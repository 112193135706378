import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  FormControl,
  FormLabel,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { PutRequestApproval } from '../pages/FlowCallAPI';
import { useNavigate, useLocation } from 'react-router-dom';

import { useFormik } from 'formik';
import { creditRepGroupRegex, paymentTermsRegex, riskCategoryRegex } from '../utils/Utils';

const UpdateModal = ({
  show,
  onClose,
  data,
  requestId,
  requestor,
  removeRequest,
  setActivePage,
  ...modalprops
}) => {
  // const [formData, setFormData] = useState({});
  console.log('modal', data);
  const navigate = useNavigate();
  const location = useLocation();

  // const setAttr = (field, value) => {
  //   setFormData({ ...formData, [field]: value });
  // };

  // const [errors, setErrors] = useState({});

  const creditApproverSubmit = async (e) => {
    data.Payer_CreditLimit = formik.values.Payer_CreditLimit;
    data.Payer_RiskCategory = formik.values.Payer_RiskCategory;
    data.Payer_Credit_Rep_Group = formik.values.Payer_Credit_Rep_Group;
    data.Payer_TermsOfPayment = formik.values.Payer_TermsOfPayment;
    data.Payer_DocumentNumber = formik.values.Payer_DocumentNumber;
    data.Payer_AccountingClerk = formik.values.Payer_AccountingClerk;

    const response = await PutRequestApproval(requestId, 'approve', '', requestor, data);

    if (response) {
      console.log('success', response);
      removeRequest();
    } else {
      console.log('error');
    }
    if (location.pathname != '/home') {
      setActivePage('approvals');
      navigate('/home');
    }
    onClose();
  };

  // useEffect(() => setFormData(data), [data]);

  const formik = useFormik({
    initialValues: {
      Payer_CreditLimit: data?.Payer_CreditLimit,
      Payer_RiskCategory: data?.Payer_RiskCategory,
      Payer_Credit_Rep_Group: data?.Payer_Credit_Rep_Group,
      Payer_TermsOfPayment: data?.Payer_TermsOfPayment,
      Payer_DocumentNumber: data?.Payer_DocumentNo,
      Payer_AccountingClerk: data?.Payer_AccountingClerk,
    },
    validate: (values) => {
      const errors = {};
      if (data['CustomerRequest'] == 'Credit Limit') {
        if (values.Payer_CreditLimit < 0) {
          errors.Payer_CreditLimit = 'Credit Limit should be greater than 0';
        } else if (values.Payer_CreditLimit == '' && values.Payer_RiskCategory == '') {
          errors.Payer_CreditLimit = 'Provide Risk or Credit Limit';
          errors.Payer_RiskCategory = 'Provide Risk or Credit Limit';
        } else if (!riskCategoryRegex.test(values.Payer_RiskCategory)) {
          errors.Payer_RiskCategory = 'Risk Category should be one alphabet only';
        }
      } else if (data['CustomerRequest'] == 'Payment Term Update') {
        if (!values.Payer_TermsOfPayment) {
          errors.Payer_TermsOfPayment = 'Payment Terms is required';
        } else if (!paymentTermsRegex.test(values.Payer_TermsOfPayment)) {
          errors.Payer_TermsOfPayment = 'Payment Terms should be 4 characters only';
        }
      } else {
        if (!values.Payer_CreditLimit) {
          errors.Payer_CreditLimit = 'Credit Limit is required';
        } else if (values.Payer_CreditLimit < 0) {
          errors.Payer_CreditLimit = 'Credit Limit should be greater than 0';
        } else if (!values.Payer_RiskCategory) {
          errors.Payer_RiskCategory = 'Risk Category is required';
        } else if (!riskCategoryRegex.test(values.Payer_RiskCategory)) {
          errors.Payer_RiskCategory = 'Risk Category should be one alphabet only';
        }

        if (
          !values.Payer_Credit_Rep_Group &&
          data['MainCountry'] != 'France' &&
          data['MainCountry'] != 'Italy' &&
          data['MainCountry'] != 'Austria'
        ) {
          errors.Payer_Credit_Rep_Group = 'Credit Rep Group is required';
        } else if (
          values.Payer_Credit_Rep_Group &&
          !creditRepGroupRegex.test(values.Payer_Credit_Rep_Group) &&
          data['MainCountry'] != 'Austria'
        ) {
          errors.Payer_Credit_Rep_Group = 'Credit Rep Group should be 2 digits only';
        }

        if (!values.Payer_TermsOfPayment) {
          errors.Payer_TermsOfPayment = 'Payment Terms is required';
        } else if (!paymentTermsRegex.test(values.Payer_TermsOfPayment)) {
          errors.Payer_TermsOfPayment = 'Payment Terms should be 4 characters only';
        }

        if (!values.Payer_AccountingClerk && data['MainCountry'] == 'Netherlands') {
          errors.Payer_AccountingClerk = 'Accounting Clerk is required';
        }
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      // setTimeout(() => {
      //   console.log(values);
      //   setSubmitting(false);
      // }, 400);
      console.log(values);
      creditApproverSubmit();
    },
  });

  useEffect(() => {
    console.log(data);
    formik.values.Payer_CreditLimit = data?.Payer_CreditLimit;
    formik.values.Payer_RiskCategory = data?.Payer_RiskCategory;
    formik.values.Payer_Credit_Rep_Group = data?.Payer_Credit_Rep_Group;
    formik.Payer_DocumentNumber = data?.Payer_DocumentNo;
    formik.values.Payer_TermsOfPayment = data?.Payer_TermsOfPayment;
    formik.values.Payer_AccountingClerk = data?.Payer_AccountingClerk;
  }, [data]);

  return data ? (
    <Modal show={show} size="lg" {...modalprops}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Credit Manager Approval - ${data['Request_ID']}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data['CustomerRequest'] == 'Credit Limit' && (
            <>
              <Row style={{ overflow: 'hidden' }}>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Payer Name`}</label>
                  <div className="form-control-textfield">{data['Payer_Name']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Country`}</label>
                  <div className="form-control-textfield">{data['MainCountry']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Requestor ID`}</label>
                  <div className="form-control-textfield">{data['Requestor_EmailID']}</div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">
                      {`Credit Limit`}
                      {` *`}
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        formik.errors.Payer_CreditLimit ? 'is-invalid' : ''
                      }`}
                      placeholder={`Credit Limit`}
                      name="Payer_CreditLimit"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_CreditLimit}
                    />
                    {formik.errors?.Payer_CreditLimit && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_CreditLimit}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">
                      {`Risk Category`}
                      {` *`}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.Payer_RiskCategory ? 'is-invalid' : ''
                      }`}
                      placeholder={`Risk Category should be one alphabet only`}
                      name="Payer_RiskCategory"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_RiskCategory}
                      maxLength={1}
                    />
                    {formik.errors?.Payer_RiskCategory && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_RiskCategory}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <br />
              {/* <Row>
            <Col xs={12} sm={6}>
              <div className="form-group">
                <label className="form-label text-left">
                  {`Credit Limit`}
                  {` *`}
                </label>
                <input
                  type="number"
                  className={`form-control ${formik.errors.Payer_CreditLimit ? 'is-invalid' : ''}`}
                  placeholder={`Credit Limit`}
                  name="Payer_CreditLimit"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Payer_CreditLimit}
                />
                {formik.errors?.Payer_CreditLimit && (
                  <div className="invalid-feedback text-left">
                    {formik.errors?.Payer_CreditLimit}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-group">
                <label className="form-label text-left">
                  {`Risk Category`}
                  {` *`}
                </label>
                <input
                  type="text"
                  className={`form-control ${formik.errors.Payer_RiskCategory ? 'is-invalid' : ''}`}
                  placeholder={`Risk Category should be one alphabet only`}
                  name="Payer_RiskCategory"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Payer_RiskCategory}
                  maxLength={1}
                />
                {formik.errors?.Payer_RiskCategory && (
                  <div className="invalid-feedback text-left">
                    {formik.errors?.Payer_RiskCategory}
                  </div>
                )}
              </div>
            </Col>
          </Row> */}
            </>
          )}
          {data['CustomerRequest'] == 'Payment Term Update' && (
            <>
              <Row style={{ overflow: 'hidden' }}>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Payer Name`}</label>
                  <div className="form-control-textfield">{data['Payer_Name']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Country`}</label>
                  <div className="form-control-textfield">{data['MainCountry']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Requestor ID`}</label>
                  <div className="form-control-textfield">{data['Requestor_EmailID']}</div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">
                      {`Terms of Payment`}
                      {` *`}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.Payer_TermsOfPayment ? 'is-invalid' : ''
                      }`}
                      placeholder={`Term of Payment`}
                      name="Payer_TermsOfPayment"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_TermsOfPayment}
                    />
                    {formik.errors?.Payer_TermsOfPayment && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_TermsOfPayment}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <br />
              {/* <Row>
            <Col xs={12} sm={6}>
              <div className="form-group">
                <label className="form-label text-left">
                  {`Credit Limit`}
                  {` *`}
                </label>
                <input
                  type="number"
                  className={`form-control ${formik.errors.Payer_CreditLimit ? 'is-invalid' : ''}`}
                  placeholder={`Credit Limit`}
                  name="Payer_CreditLimit"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Payer_CreditLimit}
                />
                {formik.errors?.Payer_CreditLimit && (
                  <div className="invalid-feedback text-left">
                    {formik.errors?.Payer_CreditLimit}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-group">
                <label className="form-label text-left">
                  {`Risk Category`}
                  {` *`}
                </label>
                <input
                  type="text"
                  className={`form-control ${formik.errors.Payer_RiskCategory ? 'is-invalid' : ''}`}
                  placeholder={`Risk Category should be one alphabet only`}
                  name="Payer_RiskCategory"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Payer_RiskCategory}
                  maxLength={1}
                />
                {formik.errors?.Payer_RiskCategory && (
                  <div className="invalid-feedback text-left">
                    {formik.errors?.Payer_RiskCategory}
                  </div>
                )}
              </div>
            </Col>
          </Row> */}
            </>
          )}
          {data['Request_Type'] == 'Creation' && (
            <>
              <Row style={{ overflow: 'hidden' }}>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Payer Name`}</label>
                  <div className="form-control-textfield">{data['Name']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Payer Address`}</label>
                  <div className="form-control-textfield">{`${data['Payer_HouseNumber']}, ${data['Payer_Street']}`}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Payer Country`}</label>
                  <div className="form-control-textfield">{`${data['Payer_Country']}, ${data['Payer_Street']}`}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Payer Postal Code`}</label>
                  <div className="form-control-textfield">{data['Payer_PostCode']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Forecasted Volume`}</label>
                  <div className="form-control-textfield">{data['Payer_YearlyVolumeForecast']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Payment Terms`}</label>
                  <div className="form-control-textfield">{data['Payer_TermsOfPayment']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`VAT Number`}</label>
                  <div className="form-control-textfield">{data['Payer_VAT']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`KVK/Company Name`}</label>
                  <div className="form-control-textfield">{data['Payer_CompanyNum']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Rental`}</label>
                  <div className="form-control-textfield">{data['Rental']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Ship-To Type`}</label>
                  <div className="form-control-textfield">{data['ShipToType']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Main Requestor 1`}</label>
                  <div className="form-control-textfield">{data['MainRequest1']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Requestor ID`}</label>
                  <div className="form-control-textfield">{data['Requestor_EmailID']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Reason for Payment Term`}</label>
                  <div className="form-control-textfield">{data['Payer_ReasonforPaymentTerm']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Segment`}</label>
                  <div className="form-control-textfield">{data['ShipTo_AttributeType3']}</div>
                </Col>
                <Col xs={6} sm={4}>
                  <label className="form-label">{`Sub-Segment`}</label>
                  <div className="form-control-textfield">{data['ShipTo_AttributeType4']}</div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">
                      {`Credit Limit`}
                      {` *`}
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        formik.errors.Payer_CreditLimit ? 'is-invalid' : ''
                      }`}
                      placeholder={`Credit Limit`}
                      name="Payer_CreditLimit"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_CreditLimit}
                    />
                    {formik.errors?.Payer_CreditLimit && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_CreditLimit}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">
                      {`Risk Category`}
                      {` *`}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.Payer_RiskCategory ? 'is-invalid' : ''
                      }`}
                      placeholder={`Risk Category should be one alphabet only`}
                      name="Payer_RiskCategory"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_RiskCategory}
                      maxLength={1}
                    />
                    {formik.errors?.Payer_RiskCategory && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_RiskCategory}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">
                      {`Credit Rep Group`}
                      {data['MainCountry'] === 'France' || data['MainCountry'] === 'Italy'
                        ? null
                        : ` *`}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.Payer_Credit_Rep_Group ? 'is-invalid' : ''
                      }`}
                      placeholder={`Credit Rep Group should be 2 digits only`}
                      name="Payer_Credit_Rep_Group"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_Credit_Rep_Group}
                      maxLength={2}
                    />
                    {formik.errors?.Payer_Credit_Rep_Group && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_Credit_Rep_Group}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">
                      {`Payment Terms`}
                      {` *`}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.Payer_TermsOfPayment ? 'is-invalid' : ''
                      }`}
                      placeholder={`Payment Terms should be 4 characters only`}
                      name="Payer_TermsOfPayment"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_TermsOfPayment}
                      maxLength={4}
                    />
                    {formik.errors?.Payer_TermsOfPayment && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_TermsOfPayment}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-group">
                    <label className="form-label text-left">{`Document Number`}</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.Payer_DocumentNumber ? 'is-invalid' : ''
                      }`}
                      placeholder={``}
                      name="Payer_DocumentNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Payer_DocumentNumber}
                    />
                    {formik.errors?.Payer_DocumentNumber && (
                      <div className="invalid-feedback text-left">
                        {formik.errors?.Payer_DocumentNumber}
                      </div>
                    )}
                  </div>
                </Col>
                {data['MainCountry'] == 'Netherlands' && (
                  <Col xs={12} sm={6}>
                    <div className="form-group">
                      <label className="form-label text-left">
                        {`Accounting Clerk`}
                        {` *`}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.Payer_AccountingClerk ? 'is-invalid' : ''
                        }`}
                        placeholder={`Accounting Clerk Should be 2 Characters`}
                        name="Payer_AccountingClerk"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Payer_AccountingClerk}
                        maxLength={2}
                      />
                      {formik.errors?.Payer_AccountingClerk && (
                        <div className="invalid-feedback text-left">
                          {formik.errors?.Payer_AccountingClerk}
                        </div>
                      )}
                    </div>
                  </Col>
                )}
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" size="sm" onClick={onClose}>
            <FeatherIcon icon="x" />
            {` Cancel`}
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            <FeatherIcon icon="check" />
            {` Update`}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  ) : (
    <React.Fragment />
  );
};

export default UpdateModal;
