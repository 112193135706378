import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../../../utils/Utils';
import axios from 'axios';
// import axios from './../../../../utils/axios';

export function FetchRegions(country, isDisableFields) {
  const url = configData.fetchRegionsURL;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setRegions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country != '') getData();
  }, [country]);

  return { loading, regions, getData };
}

// export function FetchSegments(country) {
//   const url = configData.fetchSegmentsURL;
//   const [loading, setLoading] = useState(false);
//   const [segments, setSegments] = useState([]);

//   const getData = async () => {
//     setLoading(true);
//     const res = await axios.post(url, { country: country, shipToType: 'On-Trade ' });
//     setSegments(res.data ?? []);
//     setLoading(false);
//   };

//   useEffect(() => {
//     getData();
//   }, [url]);

//   return { loading, segments, getData };
// }

// export function FetchSubSegments(country, shipToType, segment, isDisableFields, fetchSubSegments) {
//   const url = configData.fetchSubSegmentsURL;
//   const [loading, setLoading] = useState(false);
//   const [subSegments, setSubSegments] = useState([]);
//   var shipToKeyword;
//   const getData = async () => {
//     setLoading(true);
//     const res = await axios.post(url, {
//       country: country,
//       shipToType: 'On-Trade ',
//       segment: segment,
//     });
//     setSubSegments(res.data ?? []);
//     setLoading(false);
//   };

//   useEffect(() => {
//     getData();
//   }, [url]);

//   return { loading, subSegments, getData };
// }

export function FetchSegments() {
  const url = configData.fetchSegmentsURL;
  const [loading, setLoading] = useState(false);
  const [segments, setSegments] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setSegments(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, segments, getData };
}

export function FetchSubSegments() {
  const url = configData.fetchSubSegmentsURL;
  const [loading, setLoading] = useState(false);
  const [subSegments, setSubSegments] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setSubSegments(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, subSegments, getData };
}
