import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../../utils/Utils';
import axios from 'axios';
// import axios from './../../../utils/axios';

export function FetchPriceList(country) {
  const url = configData.fetchPriceListURL;
  const [loading, setLoading] = useState(false);
  const [pricelist, setpriceList] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setpriceList(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
    else if (country == '') setpriceList([]);
  }, [country]);

  return { loading, pricelist, getData };
}

export function FetchPartnerTypes(country) {
  const url = configData.fetchPartnerTypesURL;
  const [loading, setLoading] = useState(false);
  const [partnerTypes, setPartnerTypes] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setPartnerTypes(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country != '') {
      console.log('hello from partner types');
      getData();
    }
  }, [country]);

  return { loading, partnerTypes, getData };
}

export function FetchPaymentTerms(country) {
  const url = configData.fetchPaymentTermsURL;
  const [loading, setLoading] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country,
    });
    setPaymentTerms(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, paymentTerms, getData };
}

export function FetchEDTypes(country) {
  const url = configData.fetchEDTypesURL;
  const [loading, setLoading] = useState(false);
  const [EDTypes, setEDTypes] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setEDTypes(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country != '') {
      console.log('hello from ED types');
      getData();
    }
  }, [country]);

  return { loading, EDTypes, getData };
}

export function FetchRegions(country, isDisableFields) {
  const url = configData.fetchRegionsURL;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setRegions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country != '') getData();
  }, [country]);

  return { loading, regions, getData };
}

export function FetchPriceLists(country, isDisableFields) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      console.log(country);
      const res = await axios.post(urls.fetchPriceListsURL, {
        country: country,
      });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country != '') {
      getData();
    }
  }, [country]);

  return { loading, isError, result, getData };
}

export function FetchPayerBapi(payerID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchPayerBapiURL;
  const [loading, setLoading] = useState(false);
  const [payerData, setPayerData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: { payerID: payerID, companyCode: companyCode, salesOrg: salesOrg },
    });
    setPayerData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    console.log(isCopying, payerID, companyCode, salesOrg);
    if (isCopying && payerID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello');
      getData();
    }
  }, [payerID, companyCode, salesOrg, isCopying]);

  return { loading, payerData, getData };
}

export function FetchSoldToBapi(soldToID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchSoldToBapiURL;
  const [loading, setLoading] = useState(false);
  const [soldToData, setSoldToData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: { soldToID: soldToID, companyCode: companyCode, salesOrg: salesOrg },
    });
    setSoldToData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    if (isCopying && soldToID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello from sold to');
      getData();
    }
  }, [soldToID, companyCode, salesOrg, isCopying]);

  return { loading, soldToData, getData };
}

export function FetchShipToBapi(shipToID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchShipToBapiURL;
  const [loading, setLoading] = useState(false);
  const [shipToData, setShipToData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: { shipToID: shipToID, companyCode: companyCode, salesOrg: salesOrg },
    });
    setShipToData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    if (isCopying && shipToID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello from ship to');
      getData();
    }
  }, [shipToID, companyCode, salesOrg, isCopying]);

  return { loading, shipToData, getData };
}

export function FetchBillToBapi(billToID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchBillToBapiURL;
  const [loading, setLoading] = useState(false);
  const [billToData, setBillToData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: { billToID: billToID, companyCode: companyCode, salesOrg: salesOrg },
    });
    setBillToData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    if (isCopying && billToID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello from bill to');
      getData();
    }
  }, [billToID, companyCode, salesOrg, isCopying]);

  return { loading, billToData, getData };
}

export function PostToSharepoint(requestor, title, isSave) {
  const url = urls.postToSharepointModificationURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    const mainPage = JSON.parse(sessionStorage.getItem('updateCustomerMainPage'));

    const blockUnblock = JSON.parse(sessionStorage.getItem('updateCustomerBlockUnblock'));

    const payerUpdate = JSON.parse(sessionStorage.getItem('updateCustomerPayerUpdate'));

    const nameDelivPlantUpdate = JSON.parse(
      sessionStorage.getItem('updateCustomerNameDelivPlantUpdate'),
    );

    const vatTaxUpdate = JSON.parse(sessionStorage.getItem('updateCustomerVatTaxUpdate'));

    const ibanUpdate = JSON.parse(sessionStorage.getItem('updateCustomerIbanUpdate'));

    const eInvPayerUpdate = JSON.parse(sessionStorage.getItem('updateCustomerEInvPayerUpdate'));

    const eInvBillUpdate = JSON.parse(sessionStorage.getItem('updateCustomerEInvBillUpdate'));

    const othersWarehouseUpdate = JSON.parse(
      sessionStorage.getItem('updateCustomerOthersWarehouseUpdate'),
    );

    const othersProfitUpdate = JSON.parse(
      sessionStorage.getItem('updateCustomerOthersProfitUpdate'),
    );

    const othersGlnUpdate = JSON.parse(sessionStorage.getItem('updateCustomerOthersGlnUpdate'));

    const othersTextUpdate = JSON.parse(sessionStorage.getItem('updateCustomerOthersTextUpdate'));

    const ldeUpdate = JSON.parse(sessionStorage.getItem('updateCustomerldeUpdate'));

    const creditLimit = JSON.parse(sessionStorage.getItem('updateCreditLimitUpdate'));

    const paymentTerm = JSON.parse(sessionStorage.getItem('updatePaymentTermChange'));

    const M1mapping = JSON.parse(sessionStorage.getItem('updateM1mappingUpdate'));

    const routeToMarket = JSON.parse(sessionStorage.getItem('updateRouteToMarketUpdate'));

    const acctgClerk = JSON.parse(sessionStorage.getItem('updateAcctgClerkUpdate'));

    const priceList = JSON.parse(sessionStorage.getItem('updatePriceListUpdate'));

    const segmentUpdate = JSON.parse(sessionStorage.getItem('updateSegmentUpdate'));

    const regulatorUpdate = JSON.parse(sessionStorage.getItem('updateRegulatorUpdate'));

    const truckTrailerUpdate = JSON.parse(sessionStorage.getItem('updateTruckTrailerUpdate'));

    const paymentMethodsUpdate = JSON.parse(sessionStorage.getItem('updatePaymentMethodsUpdate'));

    const hierarchyMapUpdate = JSON.parse(sessionStorage.getItem('updateHierarchyMapUpdate'));

    const addressUpdate = JSON.parse(sessionStorage.getItem('updateAddressUpdate'));

    const deliveryPlanUpdate = JSON.parse(sessionStorage.getItem('updateDeliveryPlanUpdate'));

    const exciseDutyUpdate = JSON.parse(sessionStorage.getItem('updateExciseDutyUpdate'));

    const closedBlockUnbUpdate = JSON.parse(sessionStorage.getItem('updateClosedBlockUnbUpdate'));

    var data = {
      requestor: requestor,
      requestType: mainPage.reqType == 'Block/Unblock' ? 'Block/Unblock' : 'Modification',
      title: title.slice(1),
      mainPage: mainPage,
      blockUnblock: blockUnblock,
      payerUpdate: payerUpdate,
      nameDelivPlantUpdate: nameDelivPlantUpdate,
      vatTaxUpdate: vatTaxUpdate,
      ibanUpdate: ibanUpdate,
      eInvPayerUpdate: eInvPayerUpdate,
      eInvBillUpdate: eInvBillUpdate,
      othersWarehouseUpdate: othersWarehouseUpdate,
      othersProfitUpdate: othersProfitUpdate,
      othersGlnUpdate: othersGlnUpdate,
      othersTextUpdate: othersTextUpdate,
      ldeUpdate: ldeUpdate,
      creditLimit: creditLimit,
      paymentTerm: paymentTerm,
      M1mapping: M1mapping,
      routeToMarket: routeToMarket,
      acctgClerk: acctgClerk,
      priceList: priceList,
      segmentUpdate: segmentUpdate,
      regulatorUpdate: regulatorUpdate,
      truckTrailerUpdate: truckTrailerUpdate,
      paymentMethodsUpdate: paymentMethodsUpdate,
      hierarchyMapUpdate: hierarchyMapUpdate,
      addressUpdate: addressUpdate,
      deliveryPlanUpdate: deliveryPlanUpdate,
      exciseDutyUpdate: exciseDutyUpdate,
      closedBlockUnbUpdate: closedBlockUnbUpdate,
    };

    try {
      const res = await axios.post(url, data);
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(isSave, title);
    if (isSave && title !== '') {
      postData();
    }
  }, [isSave]);

  return { loading, isError, result, postData };
}

export function SaveDraft(requestor, draftTicketNum, isSave) {
  const url = urls.saveModificationDraftURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    var today = new Date();
    // const title = `M${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;

    const mainPage = JSON.parse(sessionStorage.getItem('updateCustomerMainPage'));

    const blockUnblock = JSON.parse(sessionStorage.getItem('updateCustomerBlockUnblock'));

    const payerUpdate = JSON.parse(sessionStorage.getItem('updateCustomerPayerUpdate'));

    const nameDelivPlantUpdate = JSON.parse(
      sessionStorage.getItem('updateCustomerNameDelivPlantUpdate'),
    );

    const vatTaxUpdate = JSON.parse(sessionStorage.getItem('updateCustomerVatTaxUpdate'));

    const ibanUpdate = JSON.parse(sessionStorage.getItem('updateCustomerIbanUpdate'));

    const eInvPayerUpdate = JSON.parse(sessionStorage.getItem('updateCustomerEInvPayerUpdate'));

    const eInvBillUpdate = JSON.parse(sessionStorage.getItem('updateCustomerEInvBillUpdate'));

    const othersWarehouseUpdate = JSON.parse(
      sessionStorage.getItem('updateCustomerOthersWarehouseUpdate'),
    );

    const othersProfitUpdate = JSON.parse(
      sessionStorage.getItem('updateCustomerOthersProfitUpdate'),
    );

    const othersGlnUpdate = JSON.parse(sessionStorage.getItem('updateCustomerOthersGlnUpdate'));

    const othersTextUpdate = JSON.parse(sessionStorage.getItem('updateCustomerOthersTextUpdate'));

    const ldeUpdate = JSON.parse(sessionStorage.getItem('updateCustomerldeUpdate'));

    const creditLimit = JSON.parse(sessionStorage.getItem('updateCreditLimitUpdate'));

    const paymentTerm = JSON.parse(sessionStorage.getItem('updatePaymentTermChange'));

    const M1mapping = JSON.parse(sessionStorage.getItem('updateM1mappingUpdate'));

    const routeToMarket = JSON.parse(sessionStorage.getItem('updateRouteToMarketUpdate'));

    const acctgClerk = JSON.parse(sessionStorage.getItem('updateAcctgClerkUpdate'));

    const priceList = JSON.parse(sessionStorage.getItem('updatePriceListUpdate'));

    const segmentUpdate = JSON.parse(sessionStorage.getItem('updateSegmentUpdate'));

    const regulatorUpdate = JSON.parse(sessionStorage.getItem('updateRegulatorUpdate'));

    const truckTrailerUpdate = JSON.parse(sessionStorage.getItem('updateTruckTrailerUpdate'));

    const paymentMethodsUpdate = JSON.parse(sessionStorage.getItem('updatePaymentMethodsUpdate'));

    const hierarchyMapUpdate = JSON.parse(sessionStorage.getItem('updateHierarchyMapUpdate'));

    const addressUpdate = JSON.parse(sessionStorage.getItem('updateAddressUpdate'));

    const deliveryPlanUpdate = JSON.parse(sessionStorage.getItem('updateDeliveryPlanUpdate'));

    const exciseDutyUpdate = JSON.parse(sessionStorage.getItem('updateExciseDutyUpdate'));

    const closedBlockUnbUpdate = JSON.parse(sessionStorage.getItem('updateClosedBlockUnbUpdate'));

    var data = {
      requestor: requestor,
      requestType: mainPage.reqType == 'Block/Unblock' ? 'Block/Unblock' : 'Modification',
      title: draftTicketNum,
      mainPage: mainPage,
      blockUnblock: blockUnblock,
      payerUpdate: payerUpdate,
      nameDelivPlantUpdate: nameDelivPlantUpdate,
      vatTaxUpdate: vatTaxUpdate,
      ibanUpdate: ibanUpdate,
      eInvPayerUpdate: eInvPayerUpdate,
      eInvBillUpdate: eInvBillUpdate,
      othersWarehouseUpdate: othersWarehouseUpdate,
      othersProfitUpdate: othersProfitUpdate,
      othersGlnUpdate: othersGlnUpdate,
      othersTextUpdate: othersTextUpdate,
      ldeUpdate: ldeUpdate,
      creditLimit: creditLimit,
      paymentTerm: paymentTerm,
      M1mapping: M1mapping,
      routeToMarket: routeToMarket,
      acctgClerk: acctgClerk,
      priceList: priceList,
      segmentUpdate: segmentUpdate,
      regulatorUpdate: regulatorUpdate,
      truckTrailerUpdate: truckTrailerUpdate,
      paymentMethodsUpdate: paymentMethodsUpdate,
      hierarchyMapUpdate: hierarchyMapUpdate,
      addressUpdate: addressUpdate,
      deliveryPlanUpdate: deliveryPlanUpdate,
      exciseDutyUpdate: exciseDutyUpdate,
      closedBlockUnbUpdate: closedBlockUnbUpdate,
    };

    try {
      const res = await axios.post(url, data);
      setResult(res.data ?? {});
    } catch (error) {
      // setResult(res.data ?? {})
      console.log(error);
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(isSave);
    if (isSave) {
      postData();
    }
  }, [isSave]);

  return { loading, isError, result, postData };
}
