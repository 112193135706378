import React from 'react';
import { Button, Modal, Row, FormControl } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { PutRequestApproval } from '../pages/FlowCallAPI';
import { useNavigate, useLocation } from 'react-router-dom';

const ConfirmationModal = (props) => {
  const { show, onClose, children, type, requestId, requestor, removeRequest, ...modalprops } =
    props;

  const [reason, setReason] = React.useState('');

  const [reasonError, setReasonError] = React.useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  console.log('PROPS',props);

  const onConfirm = async () => {
    if (!reason && type === 'reject') return;

    const response = await PutRequestApproval(requestId, type, reason, requestor);
    console.log('Req ID', requestId, location.pathname);
    if (response) {
      console.log('success', response);
    } else {
      console.log('error');
    }
    //removeRequest();
    if (location.pathname != '/home') {
      props.setActivePage('approvals');
      navigate('/home');
    }
    onClose();
  };

  React.useEffect(() => {
    setReason('');
    setReasonError(false);
  }, [props]);

  return (
    <Modal show={show} {...modalprops}>
      <Modal.Header className={type === 'approve' ? 'text-success' : 'text-danger'}>
        <Modal.Title className="d-flex align-items-center justify-content-center text-capitalize">
          <FeatherIcon icon={type === 'approve' ? 'check-circle' : 'x-circle'} className="mr-1" />{' '}
          {type} Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        {(type === 'reject' || type === 'approve') && (
          <FormControl
            required
            isInvalid={reasonError}
            as="textarea"
            placeholder={type === 'reject' ? "Give the reson for rejection..." : "Note if any..."}
            className="mt-4"
            value={reason}
            onBlur={() => setReasonError(!reason.length)}
            onFocus={() => setReasonError(false)}
            onChange={(e) => setReason(e.currentTarget.value)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" size="sm" onClick={onClose}>
          <FeatherIcon icon="x" /> Cancel
        </Button>
        <Button
          variant="success"
          size="sm"
          disabled={(!reason && type === 'reject')}
          onClick={onConfirm}
        >
          <FeatherIcon icon="check" /> Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
