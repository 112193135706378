import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import TopBar from '../../../components/layout/TopBar';
import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';
import { validateFields as mainPageValidate } from '../mainpage/FormValidation';
import { validateFields as shipToValidate } from '../shipto/FormValidation';
import { validateFields as payerValidate } from '../payer/FormValidation';
import { validateFields as soldToValidate } from '../soldto/FormValidation';
import { validateFields as billToValidate } from '../billto/FormValidation';
import { PostToSharepoint } from './FlowCallAPI';

const CreateCustReview = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const [isDisable, setIsDisable, isDisableRef] = useState(false);

  const [isMobile, setIsMobile, isMobileRef] = useState(window.innerWidth < 992);

  const [isSave, setIsSave, isSaveRef] = useState(false);

  const [isSaveRequest, setIsSaveRequest, isSaveRequestRef] = useState(false);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen, isSidebarOpenRef] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const mainPageForm =
    sessionStorage.getItem('createCustomerMainPage') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const shipToForm =
    sessionStorage.getItem('createCustomerShipTo') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  const payerForm =
    sessionStorage.getItem('createCustomerPayer') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerPayer'));
  const soldToForm =
    sessionStorage.getItem('createCustomerSoldTo') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));
  const billToForm =
    sessionStorage.getItem('createCustomerBillTo') === null
      ? {}
      : JSON.parse(sessionStorage.getItem('createCustomerBillTo'));

  // const postToSharepointRes = PostToSharepoint(mainPageForm, shipToForm, payerForm, soldToForm, billToForm);

  const postToSharepointRes = PostToSharepoint(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    props.draftTicketNum,
    isSaveRequestRef.current,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    props.setActivePage(e.target.getAttribute('data-link'));
  };

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleClose = () => setShowModal(false);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [mainPageErrors, setMainPageErrors] = useState({});
  const [shipToErrors, setShipToErrors] = useState({});
  const [payerErrors, setPayerErrors] = useState({});
  const [soldToErrors, setSoldToErrors] = useState({});
  const [billToErrors, setBillToErrors] = useState({});

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSave = () => {
    // setShowSubmitModal(true);
    console.log('CUSTOMER TYPE', mainPageForm.customerReqType);
    setIsSave(true);
    console.log(isSaveRef.current, mainPageForm.customerReqType == 'Ship To');
    if (mainPageForm.customerReqType == 'Ship To') {
      setMainPageErrors(mainPageValidate(mainPageForm));
      setShipToErrors(shipToValidate(shipToForm, true));
    } else {
      setMainPageErrors(mainPageValidate(mainPageForm));
      setShipToErrors(shipToValidate(shipToForm, true));
      setPayerErrors(payerValidate(payerForm));
      setSoldToErrors(soldToValidate(soldToForm));
      setBillToErrors(billToValidate(billToForm));
      // props.saveDraft();
    }
  };

  useEffect(
    () => {
      console.log(
        'Error Check',
        `${JSON.stringify(mainPageErrors)} ${JSON.stringify(shipToErrors)} ${JSON.stringify(
          payerErrors,
        )} ${JSON.stringify(soldToErrors)} ${JSON.stringify(billToErrors)} ${isSaveRef.current}`,
      );
      if (
        Object.keys(mainPageErrors).length == 0 &&
        Object.keys(shipToErrors).length == 0 &&
        Object.keys(payerErrors).length == 0 &&
        Object.keys(soldToErrors).length == 0 &&
        Object.keys(billToErrors).length == 0 &&
        isSaveRef.current
      ) {
        console.log('Can save');
        props.saveDraft();
      } else if (
        Object.keys(mainPageErrors).length != 0 ||
        Object.keys(shipToErrors).length != 0 ||
        Object.keys(payerErrors).length != 0 ||
        Object.keys(soldToErrors).length != 0 ||
        Object.keys(billToErrors).length != 0
      ) {
        console.log('Found errors');
        setShowErrorModal(true);
        setIsSave(true);
      } else {
        console.log('Some FAilure');
        setIsSave(false);
      }
    },
    [billToErrors, mainPageErrors],
    isSaveRef,
  );

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    if (props.draftMsg !== '') {
      console.log(props.draftMsg);
      setIsSaveRequest(true);
    }
  }, [props.draftMsg]);

  useEffect(() => {
    setIsSaveRequest(false);
    setIsSave(false);
    if (Object.keys(postToSharepointRes.result).length !== 0) {
      props.setActivePage('success');
    } else if (postToSharepointRes.isError) {
      props.setActivePage('error');
    }
  }, [postToSharepointRes.result, postToSharepointRes.isError]);

  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      setIsDisable(true);
    }
  }, []);

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      // type:'submit'
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card">
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={payerForm.supportDocsBank}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal1} onHide={() => setShowModal1(false)}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={payerForm.supportDocsBank1}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={() => setShowModal2(false)}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={payerForm.supportDocsBank2}></img>
          </Modal.Body>
        </Modal>

        <Modal show={isSave} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">
                  &nbsp;Please wait while your request is submitted
                </span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                Errors found in:
                <ul>
                  {Object.keys(mainPageErrors).length != 0 && <li>Main Page</li>}
                  {Object.keys(shipToErrors).length != 0 && <li>Ship to</li>}
                  {Object.keys(payerErrors).length != 0 && <li>Payer</li>}
                  {Object.keys(soldToErrors).length != 0 && <li>Sold To</li>}
                  {Object.keys(billToErrors).length != 0 && <li>Bill To</li>}
                </ul>
                Please correct them and try again.
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <div className="col col-md"></div>
              <div className="col-auto col-md-auto text-right">
                <Button
                  className="btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setShowErrorModal(false);
                    setIsSave(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Row>
          </Modal.Footer>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              {/* <strong>Customer Data {getLang('Creation')}</strong> - {getLang('Review')} */}
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                {getLang('General Customer Creation')}
              </strong>{' '}
              - {getLang('Review')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link data-link="mainpage" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Main Page')}
                </Link>
                <Link data-link="shipto" className="btn btn-warning" onClick={handleSubmit}>
                  {'Ship To'}
                </Link>
                {mainPageForm.customerReqType !== 'Ship To' && (
                  <>
                    <Link data-link="payer" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Payer')}
                    </Link>
                    <Link data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Sold To')}
                    </Link>
                    <Link data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Bill To')}
                    </Link>
                  </>
                )}
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body text-left">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Main Page')}</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{mainPageForm.country}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Customer Request')}
                        </label>
                        <div className="form-control-textfield">{mainPageForm.customerReqType}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Ship-to Type')}</label>
                        <div className="form-control-textfield">{mainPageForm.shipToType}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('M1')}</label>
                        <div className="form-control-textfield">{mainPageForm.m1}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('M2')}</label>
                        <div className="form-control-textfield">{mainPageForm.m2}</div>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col xs={6} lg={2}>
                      <h2 style={{ color: 'rgb(177, 31, 36)' }}>{'Ship To'}</h2>
                    </Col>
                    {!['Full Creation', 'Listing Fees', 'Ship To'].includes(
                      mainPageForm.customerReqType,
                    ) && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Existing Ship-to ID')}
                          </label>
                          <div className="form-control-textfield">
                            {shipToForm.existingShipToID}
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col xs={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')}</label>
                        <div className="form-control-textfield">{shipToForm.name}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Name')} 2</label>
                        <div className="form-control-textfield">{shipToForm.name2}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Postal Code')}</label>
                        <div className="form-control-textfield">{shipToForm.postalCode}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Street')}</label>
                        <div className="form-control-textfield">{shipToForm.street}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('House Number')}</label>
                        <div className="form-control-textfield">{shipToForm.houseNumber}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('City')}</label>
                        <div className="form-control-textfield">{shipToForm.city}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Country')}</label>
                        <div className="form-control-textfield">{mainPageForm.country}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Region')}</label>
                        <div className="form-control-textfield">{shipToForm.regionDesc}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Language')}</label>
                        <div className="form-control-textfield">{shipToForm.language}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Telephone')}</label>
                        <div className="form-control-textfield">{shipToForm.generalTelephone}</div>
                      </div>
                    </Col>

                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Mobile')}</label>
                        <div className="form-control-textfield">{shipToForm.mobile}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('E-mail Address')}</label>
                        <div className="form-control-textfield">{shipToForm.email}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Contact Person')}</label>
                        <div className="form-control-textfield">{shipToForm.contactPerson}</div>
                      </div>
                    </Col>
                    {shipToForm.profitCenter !== '' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Profit Center')}</label>
                          <div className="form-control-textfield">{shipToForm.profitCenter}</div>
                        </div>
                      </Col>
                    )}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('EDI')}</label>
                        <div className="form-control-textfield">{shipToForm.ediOption}</div>
                      </div>
                    </Col>
                    {shipToForm.ediOption === 'Yes' && (
                      <>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('GLN/ILN CODE')}
                            </label>
                            <div className="form-control-textfield">{shipToForm.glnIlnCode}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('EDI Email Address')}
                            </label>
                            <div className="form-control-textfield">{shipToForm.ediEmail}</div>
                          </div>
                        </Col>
                      </>
                    )}
                    {mainPageForm.country === 'Germany' &&
                      mainPageForm.customerReqType === 'Ship To' && (
                        <Col xs={6} lg={3}>
                          <label className="form-label text-left">{getLang('LDE')}</label>
                          <div className="form-control-textfield">{shipToForm.ldeOption}</div>
                        </Col>
                      )}
                    {soldToForm.ldeOption == 'Yes' && (
                      <>
                        <Col xs={6} lg={3}>
                          <label className="form-label text-left">{getLang('LDE Type')}</label>
                          <div className="form-control-textfield">{soldToForm.ldeType}</div>
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row>
                    <Col sm={12} lg={4}>
                      <h3 style={{ color: '#e0702f' }}>{getLang('Sales Data')}</h3>
                    </Col>
                  </Row>

                  <Row>
                    {['Belgium', 'Netherlands', 'United Kingdom'].includes(
                      mainPageForm.country,
                    ) && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('BEES Relevant Flag')}
                          </label>
                          <div className="form-control-textfield">
                            {shipToForm.beesRelevantFlag ? 'Yes' : 'No'}
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Does the customer need Promo?')}
                        </label>
                        <div className="form-control-textfield">
                          {shipToForm.promoNews ? 'Yes' : 'No'}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Segment')}</label>
                        <div className="form-control-textfield">{shipToForm.segmentDesc}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Sub Segment')}</label>
                        <div className="form-control-textfield">{shipToForm.subSegmentDesc}</div>
                      </div>
                    </Col>
                    <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Excise duty type')}
                        </label>
                        <div className="form-control-textfield">{shipToForm.exciseDutyType}</div>
                      </div>
                    </Col>
                    {![
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                    ].includes(mainPageForm.country) &&
                      mainPageForm.shipToType.includes('Indirect') && (
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('POC Supplier')}
                            </label>
                            <div className="form-control-textfield">
                              {shipToForm.pocSupplierShipTo}
                            </div>
                          </div>
                        </Col>
                      )}
                    {(mainPageForm.country === 'United Kingdom' ||
                      mainPageForm.country === 'Netherlands') && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Shipping Condition')}
                          </label>
                          <div className="form-control-textfield">
                            {shipToForm.shippingCondition}
                          </div>
                        </div>
                      </Col>
                    )}
                    {(mainPageForm.country == 'Italy' ||
                      (mainPageForm.country == 'Netherlands' &&
                        mainPageForm.shipToType.startsWith('Off'))) && (
                      <>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Truck Type')}</label>
                            <div className="form-control-textfield">
                              {shipToForm.truckType} - {shipToForm.truckDesc}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Trailer Type')}
                            </label>
                            <div className="form-control-textfield">
                              {shipToForm.trailerType} - {shipToForm.trailerDesc}
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                    {mainPageForm.country === 'Sweden' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Delivery Window')}
                          </label>
                          <div className="form-control-textfield">{shipToForm.delivWindow}</div>
                        </div>
                      </Col>
                    )}
                    {mainPageForm.country === 'United Kingdom' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Hierarchy type - D Node')}
                          </label>
                          <div className="form-control-textfield">
                            {shipToForm.hierarchyTypeDNode}
                          </div>
                        </div>
                      </Col>
                    )}
                    {mainPageForm.country === 'France' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Attribute Type 7')}
                          </label>
                          <div className="form-control-textfield">{shipToForm.subChannel}</div>
                        </div>
                      </Col>
                    )}
                    {mainPageForm?.country == 'Belgium' &&
                      (mainPageForm?.shipToType.startsWith('National') ||
                        mainPageForm?.shipToType.startsWith('On-Trade') ||
                        mainPageForm?.shipToType.startsWith('Off-Trade') ||
                        mainPageForm?.shipToType.endsWith('Indirect')) && (
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attribute Type 7')}
                            </label>
                            <div className="form-control-textfield">{shipToForm?.subChannel}</div>
                          </div>
                        </Col>
                      )}
                    {/* {mainPageForm.country == 'Belgium' && mainPageForm.shipToType == 'Off-Trade – Direct' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Tier')}</label>
                          <div className="form-control-textfield">{shipToForm.tier}</div>
                        </div>
                      </Col>
                    )} */}
                    {((mainPageForm.country == 'France' &&
                      ['On-Trade – Direct', 'Wholesale– Direct'].includes(
                        mainPageForm.shipToType,
                      )) ||
                      mainPageForm.country == 'United Kingdom' ||
                      (mainPageForm.country == 'Netherlands' &&
                        mainPageForm.shipToType.endsWith('Direct')) ||
                      mainPageForm.shipToType.startsWith('Off-Trade') ||
                      mainPageForm.country == 'Italy' ||
                      (mainPageForm.country == 'Belgium' &&
                        [
                          'On-Trade – Direct',
                          'National On-Trade Direct',
                          'Off-Trade – Direct',
                          'Off-Trade - Indirect',
                          'Wholesaler - Sub Agent',
                          'Wholesaler - Main Agent',
                        ].includes(mainPageForm.shipToType))) && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Delivering Plant')}
                          </label>
                          <div className="form-control-textfield">{shipToForm.deliveringPlant}</div>
                        </div>
                      </Col>
                    )}
                    {mainPageForm.country == 'Germany' && (
                      <Col xs={6} lg={3}>
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Customer Classification')}
                          </label>
                          <div className="form-control-textfield">
                            {shipToForm.customerClassification}
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>

                  <hr />
                  {mainPageForm.customerReqType !== 'Ship To' && (
                    <>
                      <Row>
                        <Col xs={6} lg={2}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Payer')}</h2>
                        </Col>
                        {payerForm.existingPayerID && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Existing Payer ID')}
                              </label>
                              <div className="form-control-textfield">
                                {payerForm.existingPayerID}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Title')}</label>
                            <div className="form-control-textfield">{payerForm.title}</div>
                          </div>
                        </Col>
                        {mainPageForm.country == 'Belgium' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Does the customer have a VAT number?')}
                              </label>
                              <div className="form-control-textfield">{payerForm.vatExist}</div>
                            </div>
                          </Col>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Tax Number')}</label>
                            <div className="form-control-textfield">{payerForm.taxNumber}</div>
                          </div>
                        </Col>
                        {mainPageForm.country == 'France' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Tax Number 2')}
                              </label>
                              <div className="form-control-textfield">{payerForm.taxNumber}</div>
                            </div>
                          </Col>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('VAT Registration No')}.
                            </label>
                            <div className="form-control-textfield">{payerForm.vatRegNumber}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              Legal Entity / {getLang('Name')}
                            </label>
                            <div className="form-control-textfield">{payerForm.name}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Name')} 2</label>
                            <div className="form-control-textfield">{payerForm.name2}</div>
                          </div>
                        </Col>
                        {['Italy', 'United Kingdom', 'Netherlands'].includes(
                          mainPageForm.country,
                        ) && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {mainPageForm.country === 'United Kingdom'
                                  ? getLang('Company number')
                                  : getLang('KVK number')}
                              </label>
                              <div className="form-control-textfield">
                                {payerForm.companyNumber}
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Postal Code')}</label>
                            <div className="form-control-textfield">{payerForm.postalCode}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('House Number')}
                            </label>
                            <div className="form-control-textfield">{payerForm.houseNumber}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Street')}</label>
                            <div className="form-control-textfield">{payerForm.street}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('City')}</label>
                            <div className="form-control-textfield">{payerForm.city}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Country')}</label>
                            <div className="form-control-textfield">{payerForm.payerCountry}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Region')}</label>
                            <div className="form-control-textfield">{payerForm.regionDesc}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Language')}</label>
                            <div className="form-control-textfield">{payerForm.language}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Reason for Payment Term')}
                            </label>
                            <div className="form-control-textfield">
                              {payerForm.ReasonPaymentTerm}
                            </div>
                          </div>
                        </Col>
                        {mainPageForm.country == 'France' && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('EDI')}</label>
                                <div className="form-control-textfield">{payerForm.ediOption}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('GLN/ILN CODE')}
                                </label>
                                <div className="form-control-textfield">{payerForm.glnIlnCode}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('EDI Email Address')}
                                </label>
                                <div className="form-control-textfield">{payerForm.ediEmail}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('EDI Type')}
                                </label>
                                <div className="form-control-textfield">{payerForm.ediType}</div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Communication')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Telephone/ Fax numbers')}
                            </label>
                            <div className="form-control-textfield">{payerForm.telephone}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Mobile')}</label>
                            <div className="form-control-textfield">{payerForm.mobile}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-mail Address')}
                            </label>
                            <div className="form-control-textfield">{payerForm.email}</div>
                          </div>
                        </Col>
                        {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Yearly Volume Forecast')}</label>
                        <div className="form-control-textfield">{payerForm.yearlyVolForecastHL}</div>
                      </div>
                      </Col> */}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Contact Person')}
                            </label>
                            <div className="form-control-textfield">{payerForm.contactPerson}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('E-Invoicing')}</label>
                            <div className="form-control-textfield">{payerForm.eInvOption}</div>
                          </div>
                        </Col>
                        {payerForm.eInvOption && (
                          <>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-Invoicing')} Email Type
                                </label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvEmailType}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  General/Accounting Email
                                </label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvGeneralEmail}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">Logistics Email</label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvLogEmail}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">Invoicing Email</label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvInvEmail}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">Ordering Email</label>
                                <div className="form-control-textfield">
                                  {payerForm.eInvOrderEmail}
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Is Vendor')}</label>
                            <div className="form-control-textfield">{payerForm.isVendor}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Vendor No.')}</label>
                            <div className="form-control-textfield">{payerForm.vendorNumber}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Forecasted Volume in HL')}
                            </label>
                            <div className="form-control-textfield">
                              {payerForm.yearlyVolForecastHL}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Bank and Tax Details')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('IBAN')}</label>
                            <div className="form-control-textfield">{payerForm.iban}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Account Holder Name')}
                            </label>
                            <div className="form-control-textfield">{payerForm.accHolderName}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Bank Key')}</label>
                            <div className="form-control-textfield">{payerForm.bankKey}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Bank Account')}
                            </label>
                            <div className="form-control-textfield">{payerForm.bankAcc}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('SWIFT/ BIC Code')}
                            </label>
                            <div className="form-control-textfield">{payerForm.swiftBicCode}</div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                            </label>
                            <div className="form-control-textfield">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    ['.jpeg', '.jpg', '.png'].includes(
                                      fileRegex.exec(payerForm.supportDocsBankName)[0],
                                    )
                                  ) {
                                    setShowModal(true);
                                  }
                                }}
                              >
                                {payerForm.supportDocsBankName}
                              </a>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 2')}
                            </label>
                            <div className="form-control-textfield">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    ['.jpeg', '.jpg', '.png'].includes(
                                      fileRegex.exec(payerForm.supportDocsBankName1)[0],
                                    )
                                  ) {
                                    setShowModal1(true);
                                  }
                                }}
                              >
                                {payerForm.supportDocsBankName1}
                              </a>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 3')}
                            </label>
                            <div className="form-control-textfield">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    ['.jpeg', '.jpg', '.png'].includes(
                                      fileRegex.exec(payerForm.supportDocsBankName2)[0],
                                    )
                                  ) {
                                    setShowModal2(true);
                                  }
                                }}
                              >
                                {payerForm.supportDocsBankName2}
                              </a>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('Company Data')}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Terms of payment')}
                            </label>
                            <div className="form-control-textfield">{payerForm.paymentTerms}</div>
                          </div>
                        </Col>
                        {mainPageForm.country == 'Germany' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">Regulator</label>
                              <div className="form-control-textfield">{payerForm.regulator}</div>
                            </div>
                          </Col>
                        )}
                        {/* <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">Does the customer have rent?</label>
                        <div className="form-control-textfield">{payerForm.customerRent}</div>
                      </div>
                      </Col>
                      <Col xs={6} lg={3}>
                      <div className="form-group">
                        <label className="form-label text-left">PEC</label>
                        <div className="form-control-textfield">{payerForm.pec}</div>
                      </div>
                      </Col> */}
                        {mainPageForm.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Does the customer have rent?')}
                              </label>
                              <div className="form-control-textfield">{payerForm.rent}</div>
                            </div>
                          </Col>
                        )}
                        {mainPageForm.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Payment Method')}
                              </label>
                              <div className="form-control-textfield">
                                {payerForm.paymentMethod}
                              </div>
                            </div>
                          </Col>
                        )}
                        {mainPageForm.country == 'Netherlands' && payerForm.rent == 'No' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Reason why Direct Debit is not applicable')}
                              </label>
                              <div className="form-control-textfield">
                                {payerForm.reasonDirectDebit}
                              </div>
                            </div>
                          </Col>
                        )}

                        {mainPageForm.country == 'Italy' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('SDI Code')}</label>
                              <div className="form-control-textfield">{payerForm.sdiCode}</div>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <hr />

                      <Row>
                        <Col sm={12} lg={4}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Sold To')}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} lg={4}>
                          <h3 style={{ color: '#e0702f' }}>{getLang('General Data')}</h3>
                        </Col>
                      </Row>
                      {!['Wholesaler - On Trade'].includes(mainPageForm.shipToType) && (
                        <Row>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Name')}</label>
                              <div className="form-control-textfield">
                                {mainPageForm.country == 'Belgium'
                                  ? (payerForm.name + shipToForm.name).slice(
                                      0,
                                      Math.min((shipToForm.name + ' ' + payerForm.name).length, 40),
                                    )
                                  : (mainPageForm.country == 'Netherlands' ||
                                      mainPageForm.country == 'Italy') &&
                                    mainPageForm.customerReqType == 'Prospect'
                                  ? shipToForm.name + 'PROSPECT'
                                  : payerForm.name}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Name')} 2</label>
                              <div className="form-control-textfield">
                                {mainPageForm.country == 'Netherlands'
                                  ? shipToForm.name2
                                  : ['France', 'United Kingdom', 'Belgium'].includes(
                                      mainPageForm.country,
                                    )
                                  ? shipToForm.name
                                  : mainPageForm.country == 'Germany'
                                  ? shipToForm.name
                                  : ''}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Postal Code')}
                              </label>
                              <div className="form-control-textfield">{shipToForm.postalCode}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Street')}</label>
                              <div className="form-control-textfield">{shipToForm.street}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('House Number')}
                              </label>
                              <div className="form-control-textfield">{shipToForm.houseNumber}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('City')}</label>
                              <div className="form-control-textfield">{shipToForm.city}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Country')}</label>
                              <div className="form-control-textfield">{mainPageForm.country}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Region')}</label>
                              <div className="form-control-textfield">{shipToForm.regionDesc}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Language')}</label>
                              <div className="form-control-textfield">{shipToForm.language}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Telephone')}</label>
                              <div className="form-control-textfield">
                                {shipToForm.generalTelephone}
                              </div>
                            </div>
                          </Col>

                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('Mobile')}</label>
                              <div className="form-control-textfield">{shipToForm.mobile}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('E-mail Address')}
                              </label>
                              <div className="form-control-textfield">{shipToForm.email}</div>
                            </div>
                          </Col>
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Contact Person')}
                              </label>
                              <div className="form-control-textfield">
                                {shipToForm.contactPerson}
                              </div>
                            </div>
                          </Col>
                          {shipToForm.profitCenter !== '' && (
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Profit Center')}
                                </label>
                                <div className="form-control-textfield">
                                  {shipToForm.profitCenter}
                                </div>
                              </div>
                            </Col>
                          )}
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">{getLang('EDI')}</label>
                              <div className="form-control-textfield">{shipToForm.ediOption}</div>
                            </div>
                          </Col>
                          {shipToForm.ediOption === 'Yes' && (
                            <>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('GLN/ILN CODE')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {shipToForm.glnIlnCode}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('EDI Email Address')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {shipToForm.ediEmail}
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                          {mainPageForm.country === 'Germany' &&
                            mainPageForm.customerReqType === 'Ship To' && (
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('LDE')}</label>
                                <div className="form-control-textfield">{shipToForm.ldeOption}</div>
                              </Col>
                            )}
                          {soldToForm.ldeOption == 'Yes' && (
                            <>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('LDE Type')}
                                </label>
                                <div className="form-control-textfield">{soldToForm.ldeType}</div>
                              </Col>
                            </>
                          )}
                        </Row>
                      )}
                      <Row>
                        {['Belgium', 'Netherlands', 'France', 'Luxembourg'].includes(
                          mainPageForm.country,
                        ) && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Vat Registration Number')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.vatRegNumber != soldToForm.vatRegNumber
                                ? ''
                                : payerForm.vatRegNumber}
                            </div>
                          </Col>
                        )}
                        {mainPageForm.country == 'United Kingdom' && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Attribute Type 9')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.attributeType9}
                            </div>
                          </Col>
                        )}

                        {['Netherlands', 'United Kingdom'].includes(mainPageForm.country) && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Hierarchy type - A Node')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.hierarchyTypeANode}
                            </div>
                          </Col>
                        )}

                        {mainPageForm.country == 'United Kingdom' && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">{getLang('A-Node')}</label>
                            <div className="form-control-textfield">{soldToForm.aNode}</div>
                          </Col>
                        )}

                        {mainPageForm.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Hierarchy Number')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.hierarchyNumber}
                            </div>
                          </Col>
                        )}

                        {mainPageForm.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">{getLang('Drop Size')}</label>
                            <div className="form-control-textfield">{soldToForm.dropSize}</div>
                          </Col>
                        )}

                        {(mainPageForm.country == 'Belgium' &&
                          mainPageForm.shipToType == 'On-Trade – Direct') ||
                          (['Netherlands'].includes(mainPageForm.country) && (
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Does the customer need Promo?')}
                                </label>
                                <div className="form-control-textfield">
                                  {soldToForm.promoNews ? 'Yes' : 'No'}
                                </div>
                              </div>
                            </Col>
                          ))}

                        {mainPageForm.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Delivery Day')}
                            </label>
                            <div className="form-control-textfield">{soldToForm.deliveryDay}</div>
                          </Col>
                        )}

                        {mainPageForm.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('VERWERKINGSTOESLAG')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.verwerkingstoeslag}
                            </div>
                          </Col>
                        )}
                        {mainPageForm.country == 'Netherlands' && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Condition Group 2')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.conditionGroup2Desc}
                            </div>
                          </Col>
                        )}
                        {['Netherlands', 'Belgium', 'France'].includes(mainPageForm.country) && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Customer Group 3')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.customerGroup3Desc}
                            </div>
                          </Col>
                        )}
                        {((mainPageForm.country == 'Belgium' &&
                          mainPageForm.shipToType == 'On-Trade – Indirect') ||
                          (['Germany', 'Netherlands'].includes(mainPageForm.country) &&
                            mainPageForm.shipToType.includes('Indirect'))) && (
                          <>
                            <Col xs={6} lg={3}>
                              <label className="form-label text-left">{`${
                                mainPageForm.shipToType.includes('Direct')
                                  ? getLang('Attribute 7 / Cellar beer')
                                  : getLang('Use of 2sold-To')
                              }`}</label>
                              <div className="form-control-textfield">{soldToForm.att7}</div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <label className="form-label text-left">
                                {getLang('2 sold-To Payer Number')}
                              </label>
                              <div className="form-control-textfield">
                                {soldToForm.soldToPayer2}
                              </div>
                            </Col>
                          </>
                        )}

                        {(['France', 'Italy'].includes(mainPageForm.country) ||
                          (mainPageForm.country == 'Germany' &&
                            mainPageForm.customerReqType != '2nd Sold To') ||
                          (mainPageForm.country == 'Belgium' &&
                            !mainPageForm.shipToType.startsWith('Wholesaler'))) && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('INCO Terms 1')}
                            </label>
                            <div className="form-control-textfield">{soldToForm.incoTerms1}</div>
                          </Col>
                        )}

                        {(['France'].includes(mainPageForm.country) ||
                          (mainPageForm.country == 'Germany' &&
                            mainPageForm.customerReqType != '2nd Sold To')) && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('INCO Terms 2')}
                            </label>
                            <div className="form-control-textfield">{soldToForm.incoTerms2}</div>
                          </Col>
                        )}

                        {mainPageForm.country == 'Belgium' &&
                          (mainPageForm.shipToType.startsWith('National') ||
                            [
                              'Off-Trade – Direct',
                              'On-Trade – Direct',
                              'On-Trade – Indirect',
                            ].includes(mainPageForm.shipToType)) && (
                            <>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Pricing Hierarchy')}
                                  {'?'}
                                </label>
                                <div className="form-control-textfield">
                                  {soldToForm.pricingHierarchyOpt}
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Pricing Hierarchy')}
                                </label>
                                <div className="form-control-textfield">
                                  {soldToForm.pricingHierarchy}
                                </div>
                              </Col>
                            </>
                          )}

                        {mainPageForm.country == 'Belgium' &&
                          mainPageForm.shipToType.startsWith('Wholesaler') && (
                            <>
                              {mainPageForm.customerReqType == 'New Owner' && (
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('Attribute 7 / Cellar beer')}
                                  </label>
                                  <div className="form-control-textfield">{soldToForm.att7}</div>
                                </Col>
                              )}
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('Name')} 1</label>
                                <div className="form-control-textfield">{soldToForm.name1}</div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">{soldToForm.name}</div>
                              </Col>
                              {[
                                'K&PD_ALL_POCM (Name Merger)',
                                'K&PD_ALL_POCM_3PP (Name Merger)',
                              ].includes(soldToForm.name) && (
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('Merger')}
                                  </label>
                                  <div className="form-control-textfield">{soldToForm.merger}</div>
                                </Col>
                              )}
                              {['BC_ALL (Name Buying Group)'].includes(soldToForm.name) && (
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('Buying Group')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {soldToForm.buyingGroup}
                                  </div>
                                </Col>
                              )}
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Postal Code')}
                                </label>
                                <div className="form-control-textfield">
                                  {soldToForm.postalCode}
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('House Number')}
                                </label>
                                <div className="form-control-textfield">
                                  {soldToForm.houseNumber}
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('Street')}</label>
                                <div className="form-control-textfield">{soldToForm.street}</div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('City')}</label>
                                <div className="form-control-textfield">{soldToForm.city}</div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('Country')}</label>
                                <div className="form-control-textfield">
                                  {soldToForm.soldCountry}
                                </div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('Region')}</label>
                                <div className="form-control-textfield">{soldToForm.region}</div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Language')}
                                </label>
                                <div className="form-control-textfield">{soldToForm.language}</div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('Telephone/Fax numbers')}
                                </label>
                                <div className="form-control-textfield">{soldToForm.telephone}</div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">{getLang('Mobile')}</label>
                                <div className="form-control-textfield">{soldToForm.mobile}</div>
                              </Col>
                              <Col xs={6} lg={3}>
                                <label className="form-label text-left">
                                  {getLang('E-mail Address')}
                                </label>
                                <div className="form-control-textfield">{soldToForm.email}</div>
                              </Col>
                            </>
                          )}

                        {([
                          'Austria',
                          'Denmark',
                          'Finland',
                          'Italy',
                          'Norway',
                          'Poland',
                          'Sweden',
                          'Switzerland',
                        ].includes(mainPageForm.country) ||
                          (mainPageForm.country == 'Belgium' &&
                            mainPageForm.shipToType == 'Off-Trade – Direct')) && (
                          <Col xs={6} lg={3}>
                            <label className="form-label text-left">
                              {getLang('Shipping Conditions')}
                            </label>
                            <div className="form-control-textfield">
                              {soldToForm.shippingCondition}
                            </div>
                          </Col>
                        )}

                        {([
                          'Austria',
                          'Denmark',
                          'Finland',
                          'France',
                          'Norway',
                          'Poland',
                          'Sweden',
                          'Switzerland',
                        ].includes(mainPageForm.country) ||
                          (mainPageForm.country == 'Germany' &&
                            mainPageForm.customerReqType != '2nd Sold To')) && (
                          <>
                            <Col xs={6} lg={3}>
                              <label className="form-label text-left">
                                {getLang('Price List')}
                              </label>
                              <div className="form-control-textfield">{soldToForm.priceList}</div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <label className="form-label text-left">{getLang('EDI')}</label>
                              <div className="form-control-textfield">{soldToForm.ediOption}</div>
                            </Col>
                            {soldToForm.ediOption == 'Yes' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('GLN/ILN CODE')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {soldToForm.glnIlnCode}
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('EDI Type')}
                                  </label>
                                  <div className="form-control-textfield">{soldToForm.ediType}</div>
                                </Col>
                              </>
                            )}
                            <Col xs={6} lg={3}>
                              <label className="form-label text-left">{getLang('LDE')}</label>
                              <div className="form-control-textfield">{soldToForm.ldeOption}</div>
                            </Col>
                            {soldToForm.ldeOption == 'Yes' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <label className="form-label text-left">
                                    {getLang('LDE Type')}
                                  </label>
                                  <div className="form-control-textfield">{soldToForm.ldeType}</div>
                                </Col>
                              </>
                            )}
                          </>
                        )}
                      </Row>

                      <hr />

                      <Row>
                        <Col xs={6} lg={2}>
                          <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Bill To')}</h2>
                        </Col>
                        <Col xs={6} lg={3}>
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Option for Bill to')}
                            </label>
                            <div className="form-control-textfield">{billToForm.showBillTo}</div>
                          </div>
                        </Col>
                        {billToForm.showBillTo === 'Yes-Fill details' && (
                          <Col xs={6} lg={3}>
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('Existing Bill To ID')}
                              </label>
                              <div className="form-control-textfield">
                                {billToForm.existingBillToID}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>

                      {billToForm.showBillTo === 'Yes-Fill details' && (
                        <>
                          <Row>
                            <Col sm={12} lg={4}>
                              <h3 style={{ color: '#e0702f' }}>{getLang('Bill To')}</h3>
                            </Col>
                          </Row>

                          <Row>
                            {mainPageForm.country != 'United Kingdom' && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">{getLang('Title')}</label>
                                  <div className="form-control-textfield">{billToForm.title}</div>
                                </div>
                              </Col>
                            )}
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Name')}</label>
                                <div className="form-control-textfield">{billToForm.name}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Postal Code')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.postalCode}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('House Number')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.houseNumber}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Street')}</label>
                                <div className="form-control-textfield">{billToForm.street}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('City')}</label>
                                <div className="form-control-textfield">{billToForm.city}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Country')}</label>
                                <div className="form-control-textfield">
                                  {billToForm.billCountry}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Region')}</label>
                                <div className="form-control-textfield">
                                  {billToForm.regionDesc}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Language')}
                                </label>
                                <div className="form-control-textfield">{billToForm.language}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Telephone')}
                                </label>
                                <div className="form-control-textfield">{billToForm.telephone}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">{getLang('Mobile')}</label>
                                <div className="form-control-textfield">{billToForm.mobile}</div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-mail Address')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.emailAddress}
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} lg={3}>
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('E-Invoicing')}
                                </label>
                                <div className="form-control-textfield">
                                  {billToForm.eInvOption}
                                </div>
                              </div>
                            </Col>
                            {billToForm.eInvOption && (
                              <Col xs={6} lg={3}>
                                <div className="form-group">
                                  <label className="form-label text-left">
                                    {getLang('E-mail address (E-invoicing)')}
                                  </label>
                                  <div className="form-control-textfield">
                                    {billToForm.eInvEmail}
                                  </div>
                                </div>
                              </Col>
                            )}
                            {mainPageForm.country == 'France' && (
                              <>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Tax Number')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {payerForm.taxNumber}
                                    </div>
                                  </div>
                                </Col>

                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Tax Number 2')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {payerForm.taxNumber}
                                    </div>
                                  </div>
                                </Col>

                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">{getLang('EDI')}</label>
                                    <div className="form-control-textfield">
                                      {billToForm.ediOption}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('GLN/ILN CODE')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {billToForm.glnIlnCode}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('EDI Email Address')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {billToForm.ediEmail}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('EDI Type')}
                                    </label>
                                    <div className="form-control-textfield">
                                      {billToForm.ediType}
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </Row>

        {/* <br /> */}

        <div className="card-footer">
          <div className="row">
            <div className="col col-md"></div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link={mainPageForm.customerReqType === 'Ship To' ? 'shipto' : 'billto'}
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            {!isDisable && (
              <div className="col-auto col-md-auto text-right">
                <Link
                  data-link="success"
                  className="btn btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={handleSave}
                >
                  {getLang('Submit')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustReview;
