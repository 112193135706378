import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../../../utils/Utils';
import axios from 'axios';
// import axios from './../../../../utils/axios';

export function FetchRegions(country, isDisableFields) {
  const url = configData.fetchRegionsURL;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setRegions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country != '') getData();
  }, [country]);

  return { loading, regions, getData };
}

export function FetchPayerBapi(payerID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchPayerBapiURL;
  const [loading, setLoading] = useState(false);
  const [payerData, setPayerData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: {
        payerID: payerID,
        companyCode: companyCode,
        salesOrg: salesOrg,
      },
    });
    setPayerData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    console.log(isCopying, payerID, companyCode, salesOrg);
    if (isCopying && payerID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello');
      getData();
    }
  }, [payerID, companyCode, salesOrg, isCopying]);

  return { loading, payerData, getData };
}

export function FetchDeliveringPlants(country, isDisableFields) {
  const url = configData.fetchDeliveryPlantsURL;
  const [loading, setLoading] = useState(false);
  const [deliveringPlants, setDeliveringPlants] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setDeliveringPlants(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') {
      getData();
    }
  }, [country]);

  return { loading, deliveringPlants, getData };
}

export function FetchShippingConditions(country, isDisableFields) {
  const url = configData.fetchShippingConditionsURL;
  const [loading, setLoading] = useState(false);
  const [shippingConditions, setShippingConditions] = useState([]);
  var countryNew;

  const getData = async () => {
    setLoading(true);
    console.log(`Country`, countryNew);
    const res = await axios.post(url, { country: countryNew });
    setShippingConditions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') {
      countryNew = country === 'United Kingdom' ? country : 'Others';
      console.log(countryNew);
      getData();
    }
  }, [country]);

  return { loading, shippingConditions, getData };
}
