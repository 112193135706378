import { useState, useEffect } from 'react';
import configData from './Config.js';
import axios from 'axios';
// import axios from './../../../../utils/axios';

export function FetchRegions(country, isDisableFields) {
  const url = configData.fetchRegionsURL;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setRegions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') getData();
  }, [country]);

  return { loading, regions, getData };
}

export function FetchPOCSuppliers(country, shipToType, isDisableFields) {
  const url = configData.fetchPOCSuppliersURL;
  const [loading, setLoading] = useState(false);
  const [pocSuppliers, setPocSuppliers] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country, shiptotype: shipToType });
    setPocSuppliers(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (
      !isDisableFields &&
      !(country in ['', 'United Kingdom'])
      // shipToType.includes('Indirect')
    ) {
      getData();
    }
  }, [country]);

  return { loading, pocSuppliers, getData };
}

export function FetchDeliveringPlants(country, isDisableFields) {
  const url = configData.fetchDeliveryPlantsURL;
  const [loading, setLoading] = useState(false);
  const [deliveringPlants, setDeliveringPlants] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setDeliveringPlants(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') {
      getData();
    }
  }, [country]);

  return { loading, deliveringPlants, getData };
}
