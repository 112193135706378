import { useState, useEffect } from 'react';
import configData from './Config';
import { urls } from '../utils/Utils';
import axios from 'axios';
// import axios from '../utils/axios';

//const mainPage = JSON.parse(sessionStorage.getItem('updateCustomerMainPage'));
// console.log(mainPage.reqType);

export function FetchApproversList(country, role, shipToType) {
  console.log('country', country, 'role', role, typeof role, 'aprpover list');
  const url = urls.fetchApproverListURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, {
        country: country,
        role: role,
        shipToType: shipToType,
      });
      console.log('RES', res.data);
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (role !== '') {
      postData();
    }
  }, [role, country]);

  return { loading, isError, result, postData };
}

export function FetchAllRequestsView(ticketNum) {
  const url = urls.fetchAllRequestsViewURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, {
        ticket: ticketNum,
      });
      console.log('RES', res.data);
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (ticketNum !== '') {
      postData();
    }
  }, [ticketNum]);

  return { loading, isError, result, postData };
}

export function FetchTicketData(
  requestor,
  ticket,
  requestType,
  isFetch,
  Status,
  CreationType,
  CustomerRequest,
  ReqType,
) {
  // || Status.includes("Duplicate")
  const url = ['D', 'M'].includes(ticket[0])
    ? urls.fetchDraftTicketDataURL
    : urls.fetchTicketDataURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, {
        review: false,
        //requestor: 'PIERRE-ANTOINE.DUVERNEY@INTERBREW.NET',
        requestor: requestor,
        ticket: ticket,
        requestType: requestType,
        creationType: CreationType,
        customerRequest: CustomerRequest,
        reqType: requestType,
      });
      console.log(res.data);
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(isFetch, ticket);
    if (isFetch && ticket !== '' && requestType != '') {
      postData();
    }
  }, [isFetch, ticket]);

  return { loading, isError, result, postData };
}

export function FetchReviewTicketData(
  requestor,
  ticket,
  isFetch,
  CreationType,
  CustomerRequest,
  ReqType,
) {
  // const url = ticket != null ? (['D', 'M'].includes(ticket[0]) ? configData.fetchDraftTicketDataURL : configData.fetchReviewTicketDataTestURL) : '';
  const url =
    ticket != null
      ? ['D', 'M'].includes(ticket[0])
        ? urls.fetchDraftTicketDataURL
        : urls.fetchTicketDataURL
      : '';
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, {
        review: true,
        ticket: ticket,
        creationType: CreationType,
        customerRequest: CustomerRequest,
        reqType: ReqType,
      });
      console.log('RES', res.data);
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isFetch && ticket !== '') {
      postData();
    }
  }, [isFetch, ticket]);

  return { loading, isError, result, postData };
}

export function FetchTranslations(lang) {
  const url = configData.fetchTranslationsURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, { language: lang });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (lang !== '') {
      getData();
    }
  }, [lang]);

  return { loading, isError, result, getData };
}

export function FetchCreateAccess(requestor) {
  const url = urls.fetchCreateAccessURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, { requestor: requestor });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (requestor != '') {
      getData();
    }
  }, [requestor]);

  return { loading, isError, result, getData };
}

export function FetchApprovalAccess(requestor) {
  const url = urls.fetchApprovalAccessURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, { requestor: requestor });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (requestor != '') {
      getData();
    }
  }, [requestor]);

  return { loading, isError, result, getData };
}

export function FetchModAccess(requestor) {
  const url = urls.fetchModAccessURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, { requestor: requestor });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(requestor);
    if (requestor != '') {
      getData();
    }
  }, [requestor]);

  return { loading, isError, result, getData };
}

export function FetchAdminAccess(requestor) {
  const url = urls.fetchAdminAccessURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url, { requestor: requestor });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(requestor);
    if (requestor != '') {
      getData();
    }
  }, [requestor]);

  return { loading, isError, result, getData };
}

export function FetchMergers(country, shipToType) {
  const url = urls.fetchMergersURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url);
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (country == 'Belgium' && shipToType.includes('Wholesaler')) {
      getData();
    }
  }, [country, shipToType]);

  return { loading, isError, result, getData };
}

export function FetchBuyingGroups(country, shipToType) {
  const url = urls.fetchBuyingGroupsURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(url);
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (country == 'Belgium' && shipToType.includes('Wholesaler')) {
      getData();
    }
  }, [country, shipToType]);

  return { loading, isError, result, getData };
}

export function FetchPriceLists(country, isDisableFields) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      console.log(country);
      const res = await axios.post(urls.fetchPriceListsURL, {
        country: country,
      });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country != '') {
      getData();
    }
  }, [country]);

  return { loading, isError, result, getData };
}

export function FetchApprovalList(requestor, type) {
  const url = urls.fetchApprovalListsURL;
  const [resData, setResData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, { params: { requestor, type } });
    setResData(res.data ?? []);
    setLoading(false);
  };
  useEffect(() => {
    console.log('use effect logic check', requestor, type);
    if (requestor !== '') {
      getData();
    }
  }, [requestor, type]);

  return { loading, resData, getData };
}

export async function FetchApprovalFilterList(
  requestor,
  requestType,
  startDate,
  endDate,
  countrySelected,
  requestSelected,
  statusSelected,
  searchTerm,
) {
  const url = urls.fetchApprovalFilterListsURL;
  try {
    const res = await axios.post(url, {
      requestor: requestor,
      requestType: requestType,
      startDate: startDate,
      endDate: endDate,
      countrySelected: countrySelected,
      requestSelected: requestSelected,
      statusSelected: statusSelected,
      searchTerm: searchTerm,
    });
    return res.data;
  } catch (error) {
    console.log('error:', error);
  }
}

export async function PutRequestApproval(title, status, message = '', requestor, rest = {}) {
  const url = urls.fetchApprovalListsURL;
  try {
    const res = await axios.put(url + `?requestor=${requestor}`, {
      title,
      status,
      message,
      ...rest,
    });

    if (res) return res.data;
  } catch (error) {
    return false;
  }
}

// used to manage approval dashboard and approval buttons
export async function FetchUserRole(usermail) {
  const url = urls.fetchUserRoleURL;
  try {
    const res = await axios.get(url, { params: { user: usermail } });
    return res.data;
  } catch (error) {
    console.log('error:', error);
  }
}

// used to manage role based access
export async function FetchRoleAccess(usermail) {
  const url = urls.fetchUserRoleAccessURL;
  try {
    const res = await axios.get(url, { params: { user: usermail } });
    return res.data;
  } catch (error) {
    console.log('error:', error);
  }
}

export function FetchSecondSoldToPayers(country, isDisableFields) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.post(urls.fetchSecondSoldToPayersURL, {
        country: country,
      });
      setResult(res.data?.result ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country != '') {
      getData();
    }
  }, [country]);

  return { loading, isError, result, getData };
}
