import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import { FetchSoldToBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';

const UpdateCustVatTaxUpdate = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);

    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      inputs.vatRegNumber != ''
    )
      setIsDisableVat(true);
  }, []);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [soldToSap, setSoldToSap] = useState(inputs.existingSoldToID);

  const [isDisableFields, setIsDisableFields] = useState(true);
  const [isDisableVat, setIsDisableVat] = useState(false);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const soldToDataRes = FetchSoldToBapi(
    inputs.existingSoldToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, false, soldToSap));
      setIsSubmitting(true);
    }
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copySoldToData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true, soldToSap));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      setIsDisableVat(false);
      Object.entries(formTemplate.vatTaxUpdate).forEach(([key, value]) => {
        if (key !== 'existingSoldToID') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(soldToDataRes.soldToData).length !== 0) {
      if (inputs.existingSoldToID != '') setSoldToSap(inputs.existingSoldToID);
      Object.entries(soldToDataRes.soldToData).forEach(([key, value]) => {
        if (key in inputs) {
          // if (key === 'telephone' && value !== '') inputs.telephoneOption = 'General/Accounting';
          if (key == 'vatRegNumber') {
            if (
              mainPageForm.country == 'Belgium' &&
              !['', '0111111111', '0999999999'].includes(value)
            )
              setIsDisableVat(true);
            else if (mainPageForm.country != 'Belgium' && value != '') setIsDisableVat(true);
          }
          // if (mainPageForm.country == 'Belgium' && key == 'vatRegNumber') {
          //   if (!['', '0111111111', '0999999999'].includes(value)) setIsDisableVat(true);
          // }
          // if (mainPageForm.country != 'Belgium' && key == 'vatRegNumber') {
          //   if (!['', '0111111111', '0999999999'].includes(value)) setIsDisableVat(true);
          // }
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerVatTaxUpdate', JSON.stringify(inputs));
      setIsDisableVat(false);
    }
    setIsCopying(false);
  }, [soldToDataRes.soldToData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg="auto">
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('VAT & TAX number update')}</h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Sold To ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingSoldToID ? ' is-invalid' : ''}`}
                          name="existingSoldToID"
                          maxLength="8"
                          placeholder={`Enter ${getLang('Existing Sold To ID')}`}
                          value={inputs.existingSoldToID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            onClick={copySoldToData}
                            disabled={isDisable || inputs.existingSoldToID == ''}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingSoldToID && (
                          <div className="invalid-feedback text-left">
                            {errors.existingSoldToID}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-auto">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Existing Payer ID')}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingPayerID ? ' is-invalid' : ''}`}
                          name="existingPayerID"
                          maxLength="8"
                          value={inputs.existingPayerID}
                          onChange={setInputs}
                          disabled
                        />
                        {errors.existingPayerID && (
                          <div className="invalid-feedback text-left">{errors.existingPayerID}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        disabled
                      />
                      {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Payer Name')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={inputs.payerName}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Country')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={mainPageForm.country}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('VAT Registration No')}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.vatRegNumber ? 'is-invalid' : ''}`}
                        name="vatRegNumber"
                        value={inputs.vatRegNumber}
                        onChange={setInputs}
                        maxLength={
                          mainPageForm.country === 'Netherlands'
                            ? 14
                            : mainPageForm.country === 'United Kingdom'
                            ? 10
                            : mainPageForm.country == 'Germany'
                            ? 11
                            : 13
                        }
                        disabled={inputs.name == '' || isDisable || isDisableVat}
                      />
                      {errors.vatRegNumber && (
                        <div className="invalid-feedback text-left">{errors.vatRegNumber}</div>
                      )}
                    </div>
                  </div>
                  {mainPageForm.country != 'Netherlands' && (
                    <div className="col-12 col-lg-3">
                      <div className="form-group">
                        <label className="form-label text-left">{getLang('Tax Number')}</label>
                        <input
                          type="text"
                          className={`form-control ${errors.taxNumber ? 'is-invalid' : ''}`}
                          name="taxNumber"
                          value={inputs.taxNumber}
                          onChange={setInputs}
                          maxLength={
                            mainPageForm.country === 'Netherlands'
                              ? 14
                              : mainPageForm.country === 'United Kingdom'
                              ? 10
                              : 13
                          }
                          disabled={inputs.name == '' || isDisable || isDisableVat}
                        />
                        {errors.taxNumber && (
                          <div className="invalid-feedback text-left">{errors.taxNumber}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="vatTaxUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingSoldToID == '' || isDisableVat || inputs.name == ''
                  ? ' disabled'
                  : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustVatTaxUpdate;
