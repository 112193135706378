import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Form, Modal, Spinner } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import {
  FetchShipToBapi,
  FetchSoldToBapi,
  FetchPayerBapi,
  FetchBillToBapi,
} from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';
import { fileRegex } from '../../../utils/Utils';

const UpdateCustBlockUnblock = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');

  const [isShipCopying, setIsShipCopying] = useState(false);
  const [copyShipClick, setCopyShipClick] = useState(false);

  const [isSoldCopying, setIsSoldCopying] = useState(false);
  const [copySoldClick, setCopySoldClick] = useState(false);

  const [isPayerCopying, setIsPayerCopying] = useState(false);
  const [copyPayerClick, setCopyPayerClick] = useState(false);

  const [isBillCopying, setIsBillCopying] = useState(false);
  const [copyBillClick, setCopyBillClick] = useState(false);

  // const [isDisableFields, setIsDisableFields] = useState(true);
  const [isDisableShipToFields, setIsDisableShipToFields] = useState(true);
  const [isDisableSoldToFields, setIsDisableSoldToFields] = useState(true);
  const [isDisablePayerFields, setIsDisablePayerFields] = useState(true);
  const [isDisableBillFields, setIsDisableBillFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const shipToDataRes = FetchShipToBapi(
    inputs.existingShipToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isShipCopying,
  );

  const payerDataRes = FetchPayerBapi(
    inputs.existingPayerID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isPayerCopying,
  );

  const soldToDataRes = FetchSoldToBapi(
    inputs.existingSoldToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isSoldCopying,
  );

  const billToDataRes = FetchBillToBapi(
    inputs.existingBillToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isBillCopying,
  );

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.orderBlockShipTo == '') inputs.orderBlockShipTo = ' _Not Required';
    if (inputs.deliveryBlockShipTo == '') inputs.deliveryBlockShipTo = ' _Not Required';
    if (inputs.billingBlockShipTo == '') inputs.billingBlockShipTo = ' _Not Required';

    if (inputs.orderBlockPayer == '') inputs.orderBlockPayer = ' _Not Required';
    if (inputs.deliveryBlockPayer == '') inputs.deliveryBlockPayer = ' _Not Required';
    if (inputs.billingBlockPayer == '') inputs.billingBlockPayer = ' _Not Required';

    if (inputs.orderBlockSoldTo == '') inputs.orderBlockSoldTo = ' _Not Required';
    if (inputs.deliveryBlockSoldTo == '') inputs.deliveryBlockSoldTo = ' _Not Required';
    if (inputs.billingBlockSoldTo == '') inputs.billingBlockSoldTo = ' _Not Required';

    if (inputs.orderBlockBillTo == '') inputs.orderBlockBillTo = ' _Not Required';
    if (inputs.deliveryBlockBillTo == '') inputs.deliveryBlockBillTo = ' _Not Required';
    if (inputs.billingBlockBillTo == '') inputs.billingBlockBillTo = ' _Not Required';

    if (inputs.nameShipTo != '') setIsDisableShipToFields(false);

    if (inputs.namePayer != '') setIsDisablePayerFields(false);

    if (inputs.nameSoldTo != '') setIsDisableSoldToFields(false);

    if (inputs.nameBillTo != '') setIsDisableBillFields(false);
  }, []);

  const [isSave, setIsSave] = useState(false);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs));
      setIsSubmitting(true);
    }
  };

  // useEffect(() => {
  //   console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
  //   if (Object.keys(errors).length === 0 && isSubmitting) {
  //     console.log('Can go forward');
  //     // navigate(linkForward);
  //     props.setActivePage(linkForward);
  //   }
  // }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copyShipToData(e) {
    setCopyShipClick(true);
    setErrors(validateFields(inputs, true, false, false, false));
    console.log(`I am true ${isShipCopying}`);
  }

  function copySoldToData(e) {
    setCopySoldClick(true);
    setErrors(validateFields(inputs, false, true, false, false));
    console.log(`I am true ${isSoldCopying}`);
  }

  function copyPayerData(e) {
    setCopyPayerClick(true);
    setErrors(validateFields(inputs, false, false, true, false));
    console.log(`I am true ${isPayerCopying}`);
  }

  function copyBillToData(e) {
    setCopyBillClick(true);
    setErrors(validateFields(inputs, false, false, false, true));
    console.log(`I am true ${isBillCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyShipClick) {
      console.log(inputs.name, isDisableShipToFields, copyShipClick);
      setCopyShipClick(false);
      setIsShipCopying(true);
      Object.entries(formTemplate.blockUnblock).forEach(([key, value]) => {
        if (['nameShipTo'].includes(key)) inputs[key] = value;
      });
    } else if (Object.keys(errors).length === 0 && copySoldClick) {
      console.log(inputs.name, isDisableShipToFields, copySoldClick);
      setCopySoldClick(false);
      setIsSoldCopying(true);
      Object.entries(formTemplate.blockUnblock).forEach(([key, value]) => {
        if (['nameSoldTo'].includes(key)) inputs[key] = value;
      });
    } else if (Object.keys(errors).length === 0 && copyPayerClick) {
      console.log(inputs.name, isDisableShipToFields, copyPayerClick);
      setCopyPayerClick(false);
      setIsPayerCopying(true);
      Object.entries(formTemplate.blockUnblock).forEach(([key, value]) => {
        if (['namePayer'].includes(key)) inputs[key] = value;
      });
    } else if (Object.keys(errors).length === 0 && copyBillClick) {
      console.log(inputs.name, isDisableShipToFields, copyBillClick);
      setCopyBillClick(false);
      setIsBillCopying(true);
      Object.entries(formTemplate.blockUnblock).forEach(([key, value]) => {
        if (['nameBillTo'].includes(key)) inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0 && isShipCopying) {
      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        // console.log(shipToDataRes.shipToData)
        if (key == 'name') {
          console.log('Got it');
          inputs.nameShipTo = value;
          // if (value == '') errors.existingShipToID = 'Ship-To ID is incorrect';
        } else if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerBlockUnblock', JSON.stringify(inputs));
      setIsDisableShipToFields(false);
    }
    setIsShipCopying(false);
  }, [shipToDataRes.shipToData]);

  useEffect(() => {
    if (Object.keys(soldToDataRes.soldToData).length !== 0 && isSoldCopying) {
      Object.entries(soldToDataRes.soldToData).forEach(([key, value]) => {
        if (key == 'name') {
          inputs.nameSoldTo = value;
          // if (value == '') errors.existingSoldToID = 'Sold-To ID is incorrect';
        } else if (key in inputs) {
          if (!['existingShipToID', 'existingBillToID', 'existingPayerID'].includes(key))
            inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerBlockUnblock', JSON.stringify(inputs));
      setIsDisableSoldToFields(false);
    }
    setIsSoldCopying(false);
  }, [soldToDataRes.soldToData]);

  useEffect(() => {
    if (Object.keys(payerDataRes.payerData).length !== 0 && isPayerCopying) {
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        if (key == 'name') {
          inputs.namePayer = value;
          // if (value == '') errors.existingPayerID = 'Payer ID is incorrect';
        } else if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerBlockUnblock', JSON.stringify(inputs));
      setIsDisablePayerFields(false);
    }
    setIsPayerCopying(false);
  }, [payerDataRes.payerData]);

  useEffect(() => {
    if (Object.keys(billToDataRes.billToData).length !== 0) {
      Object.entries(billToDataRes.billToData).forEach(([key, value]) => {
        if (key == 'name') {
          inputs.nameBillTo = value;
          // if (value == '') errors.existingBillToID = 'Bill-To ID is incorrect';
        } else if (key in inputs) {
          inputs[key] = value;
        }
      });
      sessionStorage.setItem('updateCustomerBlockUnblock', JSON.stringify(inputs));
      setIsDisableBillFields(false);
    }
    setIsBillCopying(false);
  }, [billToDataRes.billToData]);

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isShipCopying || isSoldCopying || isBillCopying || isPayerCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={inputs.supportDocsBank}></img>
        </Modal.Body>
      </Modal>

      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <Row>
              <div className="col-12 text-left">
                <Row>
                  <Col sm={12} lg={4}>
                    <h2 style={{ color: 'rgb(177, 31, 36)' }}>{getLang('Block/Unblock')}</h2>
                  </Col>
                </Row>
                <Row>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Block/Unblock')} *</label>
                      <select
                        className={`form-control ${
                          errors.blockUnblockOpt ? 'is-invalid-select' : ''
                        }`}
                        name="blockUnblockOpt"
                        onChange={setInputs}
                        value={inputs.blockUnblockOpt}
                      >
                        <option value="">Select</option>
                        <option value="Block">Block</option>
                        <option value="Unblock">Unblock</option>
                      </select>
                      {errors.blockUnblockOpt && (
                        <div className="invalid-feedback text-left">{errors.blockUnblockOpt}</div>
                      )}
                    </div>
                  </div>
                </Row>

                {inputs.blockUnblockOpt != '' && (
                  <>
                    <hr />

                    <Row>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Tick the partner to be blocked or Unblocked')}
                          </label>
                          {!['Netherlands', 'Belgium', 'France'].includes(mainPageForm.country) && (
                            <>
                              <Form.Check
                                type="checkbox"
                                name="checkShipTo"
                                label={getLang('Ship To')}
                                checked={inputs.checkShipTo == 'Y'}
                                onChange={setInputs}
                              />
                              <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <div className="col-12 col-lg-3">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Existing Ship-to ID')}
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className={`form-control${
                                          errors.existingShipToID ? ' is-invalid' : ''
                                        }`}
                                        name="existingShipToID"
                                        maxLength="8"
                                        placeholder={`Enter ${getLang('Existing Ship-to ID')}`}
                                        value={inputs.existingShipToID}
                                        onChange={setInputs}
                                        disabled={isDisable || inputs.checkShipTo != 'Y'}
                                      />
                                      <span className="input-group-append">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          style={{
                                            borderTopRightRadius: '3px',
                                            borderBottomRightRadius: '3px',
                                          }}
                                          onClick={copyShipToData}
                                          disabled={isDisable || inputs.existingShipToID == ''}
                                        >
                                          {getLang('Copy')}
                                        </button>
                                      </span>
                                      {errors.existingShipToID && (
                                        <div className="invalid-feedback text-left">
                                          {errors.existingShipToID}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-auto">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Name')}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="nameShipTo"
                                      placeholder={`${getLang('Name')}`}
                                      value={inputs.nameShipTo}
                                      onChange={setInputs}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                {inputs.blockUnblockOpt == 'Block' && (
                                  <>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {getLang('Order Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="orderBlockShipTo"
                                          onChange={setInputs}
                                          value={inputs.orderBlockShipTo}
                                          disabled={inputs.nameShipTo == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          <option value="Z2_Closed">Z2_Closed</option>
                                          <option value="ZX_Beer order block">
                                            ZX_Beer order block
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {getLang('Delivery Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="deliveryBlockShipTo"
                                          onChange={setInputs}
                                          value={inputs.deliveryBlockShipTo}
                                          disabled={inputs.nameShipTo == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          <option value="Z2_Pre sale del block">
                                            Z2_Pre sale del block
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {getLang('Billing Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="billingBlockShipTo"
                                          onChange={setInputs}
                                          value={inputs.billingBlockShipTo}
                                          disabled={inputs.nameShipTo == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          <option value="12_General block">12_General block</option>
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                          {mainPageForm.country != 'Netherlands' && (
                            <>
                              <Form.Check
                                type="checkbox"
                                name="checkPayer"
                                label={getLang('Payer')}
                                checked={inputs.checkPayer == 'Y'}
                                onChange={setInputs}
                              />
                              <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <div className="col-12 col-lg-3">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Existing Payer ID')}
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className={`form-control${
                                          errors.existingPayerID ? ' is-invalid' : ''
                                        }`}
                                        name="existingPayerID"
                                        maxLength="8"
                                        placeholder={`Enter ${getLang('Existing Payer ID')}`}
                                        value={inputs.existingPayerID}
                                        onChange={setInputs}
                                        disabled={isDisable || inputs.checkPayer != 'Y'}
                                      />
                                      <span className="input-group-append">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          style={{
                                            borderTopRightRadius: '3px',
                                            borderBottomRightRadius: '3px',
                                          }}
                                          onClick={copyPayerData}
                                          disabled={isDisable || inputs.existingPayerID == ''}
                                        >
                                          {getLang('Copy')}
                                        </button>
                                      </span>
                                      {errors.existingPayerID && (
                                        <div className="invalid-feedback text-left">
                                          {errors.existingPayerID}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-auto">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Name')}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="namePayer"
                                      placeholder={`${getLang('Name')}`}
                                      value={inputs.namePayer}
                                      onChange={setInputs}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                {inputs.blockUnblockOpt == 'Block' && (
                                  <>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {mainPageForm.country == 'France'
                                            ? `Complètement fermé? Choisissez Standard`
                                            : getLang('Order Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="orderBlockPayer"
                                          onChange={setInputs}
                                          value={inputs.orderBlockPayer}
                                          disabled={inputs.namePayer == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          <option value="Z2_Closed">{`${
                                            ['Belgium', 'France'].includes(mainPageForm.country)
                                              ? 'Fully Closed (Standard)'
                                              : 'Z2_Closed'
                                          }`}</option>
                                          {!['Belgium', 'France'].includes(
                                            mainPageForm.country,
                                          ) && (
                                            <option value="ZX_Beer order block">
                                              ZX_Beer order block
                                            </option>
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    {!['France', 'Belgium'].includes(mainPageForm.country) && (
                                      <div className="col-12 col-lg-3">
                                        <div className="form-group">
                                          <label className="form-label">
                                            {getLang('Delivery Block Type')}
                                          </label>
                                          <select
                                            className="form-control"
                                            name="deliveryBlockPayer"
                                            onChange={setInputs}
                                            value={inputs.deliveryBlockPayer}
                                            disabled={
                                              inputs.namePayer == '' ||
                                              isDisable ||
                                              ['Belgium', 'France'].includes(mainPageForm.country)
                                            }
                                          >
                                            <option value=" _Not Required">Select</option>
                                            <option value="Z2_Pre sale del block">
                                              Z2_Pre sale del block
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    )}
                                    {!['France', 'Belgium'].includes(mainPageForm.country) && (
                                      <div className="col-12 col-lg-3">
                                        <div className="form-group">
                                          <label className="form-label">
                                            {getLang('Billing Block Type')}
                                          </label>
                                          <select
                                            className="form-control"
                                            name="billingBlockPayer"
                                            onChange={setInputs}
                                            value={inputs.billingBlockPayer}
                                            disabled={inputs.namePayer == '' || isDisable}
                                          >
                                            <option value=" _Not Required">Select</option>
                                            <option value="12_General block">
                                              12_General block
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                          {
                            <>
                              <Form.Check
                                type="checkbox"
                                name="checkSoldTo"
                                label={getLang('Sold To')}
                                checked={inputs.checkSoldTo == 'Y'}
                                onChange={setInputs}
                              />
                              <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <div className="col-12 col-lg-3">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Existing Sold To ID')}
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className={`form-control${
                                          errors.existingSoldToID ? ' is-invalid' : ''
                                        }`}
                                        name="existingSoldToID"
                                        maxLength="8"
                                        placeholder={`Enter ${getLang('Existing Sold To ID')}`}
                                        value={inputs.existingSoldToID}
                                        onChange={setInputs}
                                        disabled={isDisable || inputs.checkSoldTo != 'Y'}
                                      />
                                      <span className="input-group-append">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          style={{
                                            borderTopRightRadius: '3px',
                                            borderBottomRightRadius: '3px',
                                          }}
                                          onClick={copySoldToData}
                                          disabled={isDisable || inputs.existingSoldToID == ''}
                                        >
                                          {getLang('Copy')}
                                        </button>
                                      </span>
                                      {errors.existingSoldToID && (
                                        <div className="invalid-feedback text-left">
                                          {errors.existingSoldToID}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-auto">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Name')}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="nameSoldTo"
                                      placeholder={`${getLang('Name')}`}
                                      value={inputs.nameSoldTo}
                                      onChange={setInputs}
                                      disabled
                                    />
                                  </div>
                                </div>
                                {mainPageForm.country == 'Netherlands' &&
                                  inputs.blockUnblockOpt == 'Unblock' && (
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label text-left">
                                          {getLang('Please Attach The Supporting Documents')}
                                        </label>
                                        {!inputs.supportDocsBank && (
                                          <input
                                            type="file"
                                            id="upload-support-docs"
                                            accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                            name="supportDocsBank"
                                            onChange={setInputs}
                                            disabled={inputs.name == '' || isDisable}
                                          />
                                        )}
                                        {inputs.supportDocsBank && (
                                          <div className="form-control-textfield text-left">
                                            <a
                                              href=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  ['.jpeg', '.png', '.jpg'].includes(
                                                    fileRegex.exec(inputs.supportDocsBankName)[0],
                                                  )
                                                ) {
                                                  setShowModal(true);
                                                }
                                              }}
                                            >
                                              {inputs.supportDocsBankName}
                                            </a>
                                            &nbsp;
                                            <FeatherIcon
                                              id="clearFile"
                                              icon="x"
                                              size={14}
                                              name="clearFile"
                                              onClick={setInputs}
                                              className="fe-icon"
                                            />
                                          </div>
                                        )}
                                        {errors.supportDocsBank && (
                                          <div className="invalid-feedback-file text-left">
                                            {errors.supportDocsBank}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </Row>
                              <Row>
                                {inputs.blockUnblockOpt == 'Block' && (
                                  <>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {mainPageForm.country == 'France'
                                            ? `Complètement fermé (Standard) ou Uniquement blocage commande (Exception)?`
                                            : getLang('Order Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="orderBlockSoldTo"
                                          onChange={setInputs}
                                          value={inputs.orderBlockSoldTo}
                                          disabled={inputs.nameSoldTo == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          {mainPageForm.country != 'Netherlands' && (
                                            <option value="Z2_Closed">{`${
                                              ['Belgium', 'France'].includes(mainPageForm.country)
                                                ? 'Fully Closed (Standard)'
                                                : 'Z2_Closed'
                                            }`}</option>
                                          )}
                                          <option value="ZX_Beer order block">{`${
                                            ['Belgium', 'France'].includes(mainPageForm.country)
                                              ? 'Beer order block only (Exception)'
                                              : 'ZX_Beer order block'
                                          }`}</option>
                                        </select>
                                      </div>
                                    </div>
                                    {mainPageForm.country != 'Netherlands' && (
                                      <>
                                        {!['France', 'Belgium'].includes(mainPageForm.country) && (
                                          <div className="col-12 col-lg-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                {getLang('Delivery Block Type')}
                                              </label>
                                              <select
                                                className="form-control"
                                                name="deliveryBlockSoldTo"
                                                onChange={setInputs}
                                                value={inputs.deliveryBlockSoldTo}
                                                disabled={
                                                  inputs.nameSoldTo == '' ||
                                                  isDisable ||
                                                  ['France', 'Belgium'].includes(
                                                    mainPageForm.country,
                                                  )
                                                }
                                              >
                                                <option value=" _Not Required">Select</option>
                                                <option value="Z2_Pre sale del block">
                                                  Z2_Pre sale del block
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}
                                        {!['France', 'Belgium'].includes(mainPageForm.country) && (
                                          <div className="col-12 col-lg-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                {getLang('Billing Block Type')}
                                              </label>
                                              <select
                                                className="form-control"
                                                name="billingBlockSoldTo"
                                                onChange={setInputs}
                                                value={inputs.billingBlockSoldTo}
                                                disabled={inputs.nameSoldTo == '' || isDisable}
                                              >
                                                <option value=" _Not Required">Select</option>
                                                <option value="12_General block">
                                                  12_General block
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Row>
                            </>
                          }
                          {!['Netherlands', 'Belgium', 'France'].includes(mainPageForm.country) && (
                            <>
                              <Form.Check
                                type="checkbox"
                                name="checkBillTo"
                                label={getLang('Bill To')}
                                checked={inputs.checkBillTo == 'Y'}
                                onChange={setInputs}
                              />
                              <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <div className="col-12 col-lg-3">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Existing Bill To ID')}
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className={`form-control${
                                          errors.existingBillToID ? ' is-invalid' : ''
                                        }`}
                                        name="existingBillToID"
                                        maxLength="8"
                                        placeholder={`Enter ${getLang('Existing Bill To ID')}`}
                                        value={inputs.existingBillToID}
                                        onChange={setInputs}
                                        disabled={isDisable || inputs.checkBillTo != 'Y'}
                                      />
                                      <span className="input-group-append">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          style={{
                                            borderTopRightRadius: '3px',
                                            borderBottomRightRadius: '3px',
                                          }}
                                          onClick={copyBillToData}
                                          disabled={isDisable || inputs.existingBillToID == ''}
                                        >
                                          {getLang('Copy')}
                                        </button>
                                      </span>
                                      {errors.existingBillToID && (
                                        <div className="invalid-feedback text-left">
                                          {errors.existingBillToID}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-auto">
                                  <div className="form-group">
                                    <label className="form-label text-left">
                                      {getLang('Name')}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="nameBillTo"
                                      placeholder={`${getLang('Name')}`}
                                      value={inputs.nameBillTo}
                                      onChange={setInputs}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                {inputs.blockUnblockOpt == 'Block' && (
                                  <>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {getLang('Order Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="orderBlockBillTo"
                                          onChange={setInputs}
                                          value={inputs.orderBlockBillTo}
                                          disabled={inputs.nameBillTo == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          <option value="Z2_Closed">Z2_Closed</option>
                                          <option value="ZX_Beer order block">
                                            ZX_Beer order block
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {getLang('Delivery Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="deliveryBlockBillTo"
                                          onChange={setInputs}
                                          value={inputs.deliveryBlockBillTo}
                                          disabled={inputs.nameBillTo == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          <option value="Z2_Pre sale del block">
                                            Z2_Pre sale del block
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {getLang('Billing Block Type')}
                                        </label>
                                        <select
                                          className="form-control"
                                          name="billingBlockBillTo"
                                          onChange={setInputs}
                                          value={inputs.billingBlockBillTo}
                                          disabled={inputs.nameBillTo == '' || isDisable}
                                        >
                                          <option value=" _Not Required">Select</option>
                                          <option value="12_General block">12_General block</option>
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                        </div>
                      </div>
                    </Row>
                  </>
                )}
              </div>
            </Row>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="blockUnblockReview"
              className={`btn btn-danger btn-block${
                (inputs.existingShipToID != '' && inputs.shipToName != '') ||
                (inputs.existingSoldToID != '' && inputs.soldToName != '') ||
                (inputs.existingPayerID != '' && inputs.payerName != '') ||
                (inputs.existingBillToID != '' && inputs.nameBillTo != '')
                  ? ''
                  : ' disabled'
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustBlockUnblock;
