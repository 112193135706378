export function validateFields(values, copyClick, soldToSap) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')).country;

  const soldToIDRegex = /^(1)\d{7}$/;

  const vatRegex = {
    Netherlands: /^NL\d{9}B\d{2}$/,
    'United Kingdom': /^GB\d{8}$/,
    France: /^FR\d{11}$/,
    Belgium: /^BE\d{10}$/,
    Germany: /^DE\d{9}$/,
    Denmark: /^DK\d{8}$/,
  };

  if (copyClick) {
    if (values.existingSoldToID == '') {
      errors.existingSoldToID = 'Sold To ID is required';
    } else if (values.existingSoldToID != '' && !soldToIDRegex.test(values.existingSoldToID)) {
      errors.existingSoldToID = 'Sold To ID is incorrect';
    }
  } else {
    console.log(soldToSap);
    if (values.name != '') {
      if (soldToSap != values.existingSoldToID) {
        console.log(soldToSap);
        errors.existingSoldToID = 'Sold To ID does not match';
      } else if (
        values.vatRegNumber != '' &&
        !vatRegex[country].test(values.vatRegNumber) &&
        country != 'Denmark'
      ) {
        errors.vatRegNumber = 'VAT Reg Number is incorrect';
      }
    }
  }

  // if (values.existingSoldToID == '') {
  //   errors.existingSoldToID = 'Sold To ID is required';
  // } else if (values.existingSoldToID != '' && !soldToIDRegex.test(values.existingSoldToID)) {
  //   errors.existingSoldToID = 'Sold To ID is incorrect';
  // } else if (values.name != '') {
  //   if (values.vatRegNumber == '') {
  //     errors.vatRegNumber = 'VAT Reg Number is required';
  //   } else if (values.taxNumber === '') {
  //     errors.taxNumber = 'Tax Number is required';
  //   }
  // }

  return errors;
}
