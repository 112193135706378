import { useState, useEffect } from 'react';
import configData from './Config.js';
import axios from 'axios';
// import axios from './../../../../utils/axios';

export function FetchOrderBlocks() {
  const url = configData.fetchOrderBlocksURL;
  const [loading, setLoading] = useState(false);
  const [orderBlocks, setorderBlocks] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setorderBlocks(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, orderBlocks, getData };
}

export function FetchDeliveryBlocks() {
  const url = configData.fetchDeliveryBlocksURL;
  const [loading, setLoading] = useState(false);
  const [deliveryBlocks, setdeliveryBlocks] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setdeliveryBlocks(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, deliveryBlocks, getData };
}

export function FetchBillingBlocks() {
  const url = configData.fetchBillingBlocksURL;
  const [loading, setLoading] = useState(false);
  const [billingBlocks, setbillingBlocks] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setbillingBlocks(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, billingBlocks, getData };
}
