export const adminUsers = [
  'aninda.ghosh@ab-inbev.com',
  'bhadri.maheshkumar@ab-inbev.com',
  'Raghavendrakumar.krishnappa@ab-inbev.com',
  'vedant.gautam@ab-inbev.com',
  'vamshi.thakkalapelli@ab-inbev.com',
  'sunny.anand@ab-inbev.com',
  'sandeep.cherian@ab-inbev.com',
];

// const API_ROOT_URLS = {
//   prod: `http://localhost:5000`,
//   // prod: `https://ghq-india-desk-booking-api.azurewebsites.net`,
//   // prod: `https://GHQ-HOP-A-SEAT-api.azurewebsites.net`,
//   dev: `http://localhost:5000`,
//   // dev: `https://ghq-india-desk-booking-dev-api.azurewebsites.net`,
// };

// export const API_ROOT_URL_PREFIX =
//   process.env.REACT_APP_STAGE == 'prod' ? API_ROOT_URLS.prod : API_ROOT_URLS.dev;

let API_URL_PREFIX = '';

export const prefix =
  process.env.REACT_APP_ENV === 'prod'
    ? (API_URL_PREFIX = 'https://mavenapis.ab-inbev.com')
    : process.env.REACT_APP_ENV === 'test'
    ? (API_URL_PREFIX = 'https://mavenapistest.ab-inbev.com')
    : (API_URL_PREFIX = 'http://localhost:1234');

export const urls = {
  postToSharepointCreationURL: API_URL_PREFIX + '/api/creation/submit',
  postToDBHirerachyCreationURL: API_URL_PREFIX + '/api/hirearchy/submit',
  postToSharepointModificationURL: API_URL_PREFIX + '/api/modification/submit',
  postToDBPlantCreationURL: API_URL_PREFIX + '/api/plant/submit',
  fetchPayerBapiURL: API_URL_PREFIX + '/api/data/sap/payer',
  fetchSoldToBapiURL: API_URL_PREFIX + '/api/data/sap/soldto',
  fetchShipToBapiURL: API_URL_PREFIX + '/api/data/sap/shipto',
  fetchBillToBapiURL: API_URL_PREFIX + '/api/data/sap/billto',
  fetchDraftRequestsURL: API_URL_PREFIX + '/api/drafts',
  fetchAllRequestsURL: API_URL_PREFIX + '/api/allRequests',
  fetchSubmittedRequestsURL: API_URL_PREFIX + '/api/submitted',
  fetchRejectedRequestsURL: API_URL_PREFIX + '/api/rejected',
  fetchDuplicateRequestsURL: API_URL_PREFIX + '/api/duplicate',
  fetchCompletedRequestsURL: API_URL_PREFIX + '/api/completed',
  fetchDraftFilteredRequestsURL: API_URL_PREFIX + '/api/searchDrafts',
  fetchAllFilteredRequestsURL: API_URL_PREFIX + '/api/searchAllRequests',
  fetchSubmittedFilteredRequestsURL: API_URL_PREFIX + '/api/searchSubmitted',
  fetchDuplicateFilteredRequestsURL: API_URL_PREFIX + '/api/searchDuplicate',
  fetchRejectedFilteredRequestsURL: API_URL_PREFIX + '/api/searchRejected',
  fetchCompletedFilteredRequestsURL: API_URL_PREFIX + '/api/searchCompleted',
  fetchTicketDataURL: API_URL_PREFIX + '/api/request',
  fetchCreateAccessURL: API_URL_PREFIX + '/api/creation/access',
  fetchModAccessURL: API_URL_PREFIX + '/api/modification/access',
  fetchApprovalAccessURL: API_URL_PREFIX + '/api/approval/access',
  fetchAdminAccessURL: API_URL_PREFIX + '/api/admin/access',
  saveCreationDraftURL: API_URL_PREFIX + '/api/creation/draft',
  saveModificationDraftURL: API_URL_PREFIX + '/api/modification/draft',
  fetchDraftTicketDataURL: API_URL_PREFIX + '/api/draftrequest',
  fetchMergersURL: API_URL_PREFIX + '/api/data/mergers',
  fetchBuyingGroupsURL: API_URL_PREFIX + '/api/data/buyinggroups',
  fetchPriceListsURL: API_URL_PREFIX + '/api/data/pricelists',
  deleteDraftURL: API_URL_PREFIX + '/api/draft/delete',
  addUserAccessURL: API_URL_PREFIX + '/api/management/user/add',
  removeUserAccessURL: API_URL_PREFIX + '/api/management/user/remove',
  fetchSecondSoldToPayersURL: API_URL_PREFIX + '/api/data/secondsoldtopayers',
  fetchUserRoleURL: API_URL_PREFIX + '/api/user',
  fetchUserRoleAccessURL: API_URL_PREFIX + '/api/role-based-access',
  fetchUserRoleAccessCountryURL: API_URL_PREFIX + '/api/role-based-countries',
  fetchApprovalListsURL: API_URL_PREFIX + '/api/approval',
  getApprovalButtonURL: API_URL_PREFIX + '/api/approval/button',
  fetchApprovalFilterListsURL: API_URL_PREFIX + '/api/approvalFilter',
  fetchExportsURL: API_URL_PREFIX + '/api/export',
  fetchAllFilteredExportsURL: API_URL_PREFIX + '/api/filteredExports',
  fetchAllRequestsViewURL: API_URL_PREFIX + '/api/allRequestsView',
  fetchCustomerRequestURL: API_URL_PREFIX + '/api/data/allCustomerRequest',
  fetchCountriesURL: API_URL_PREFIX + '/api/data/countries',
  fetchApproverListURL: API_URL_PREFIX + '/api/approversList',
  updateVendorDataURL: API_URL_PREFIX + '/api/updateVendor',
  fetchListingFeesPendingRequestsURL: API_URL_PREFIX + '/api/listingfeePending',
  fetchListingFeeFilteredRequestsURL: API_URL_PREFIX + '/api/searchListingFeePending',
  fetchListingFeesCompletedRequestsURL: API_URL_PREFIX + '/api/listingfeeCompleted',
  fetchListingFeeCompletedFilteredRequestsURL: API_URL_PREFIX + '/api/searchListingFeeCompleted',
};

export const dropdownUrls = {};

export const riskCategoryRegex = /^[A-Za-z]$/;
export const creditRepGroupRegex = /^[1-9][0-9]$/;
export const paymentTermsRegex = /^[A-Z0-9]{4}$/;

export const postalCodeLength = (country) => {
  switch (country) {
    case 'Austria':
      return 4;

    case 'Belgium':
      return 4;

    case 'Denmark':
      return 4;

    case 'Finland':
      return 5;

    case 'France':
      return 5;

    case 'Germany':
      return 5;

    case 'Italy':
      return 5;

    case 'Luxembourg':
      return 4;

    case 'Netherlands':
      return 9;

    case 'Norway':
      return 4;

    case 'Poland':
      return 6;

    case 'Sweden':
      return 6;

    case 'Switzerland':
      return 4;

    case 'United Kingdom':
      return 9;

    default:
      return 9;
  }
};

export const postalCodeRegex = {
  '': /(.*?)/,
  Austria: /^[1-9]\d{3}/,
  Belgium: /^[1-9]\d{3}/,
  Denmark: /^[1-9]\d{3}/,
  Finland: /^\d{5}$/,
  France: /^\d{5}$/,
  Germany: /^\d{5}$/,
  Italy: /^\d{5}$/,
  Luxembourg: /^\d{4}$/,
  Netherlands: /^[1-9]\d{3}\s{1}[A-Za-z]{2}$/,
  Norway: /^\d{4}$/,
  Poland: /^\d{2}[- ]{1}\d{3}$/,
  Sweden: /^\d{3}\s*\d{2}$/,
  Switzerland: /^[1-9]\d{3}/,
  'United Kingdom':
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
};

export const postalCodePlaceholder = (country) => {
  switch (country) {
    case 'Austria':
      return 'Eg: 1234';

    case 'Belgium':
      return 'Eg: 1234';

    case 'Denmark':
      return 'Eg: 1234';

    case 'Finland':
      return 'Eg: 12345';

    case 'France':
      return 'Eg: 12345';

    case 'Germany':
      return 'Eg: 12345';

    case 'Italy':
      return 'Eg: 12345';

    case 'Luxembourg':
      return 'Eg: 1234';

    case 'Netherlands':
      return 'Eg: 6811 JZ';

    case 'Norway':
      return 'Eg: 1234';

    case 'Poland':
      return 'Eg: 12-345';

    case 'Sweden':
      return 'Eg: 123 45';

    case 'Switzerland':
      return 'Eg: 1234';

    case 'United Kingdom':
      return 'Eg: EX34 9QB';

    default:
      return 'Eg: 123456789';
  }
};

export const ibanRegex = {
  Austria: /^AT\d{18}$/,
  Belgium: /^BE\d{14}$/,
  Denmark: /^DK\d{16}$/,
  Finland: /^FI\d{16}$/,
  France: /^FR\d{12}[A-Z0-9]{11}\d{2}$/,
  Germany: /^DE\d{20}$/,
  Italy: /^IT\d{2}[A-Z][A-Z0-9]{22}$/,
  Luxembourg: /^LU\d{18}$/,
  Netherlands: /^NL\d{2}[A-Z]{4}\d{9,10}$/,
  Norway: /^NO\d{13}$/,
  Poland: /^PL\d{26}$/,
  Sweden: /^SE\d{22}$/,
  Switzerland: /^CH\d{19}$/,
  'United Kingdom': /^GB\d{2}[A-Z]{4}\d{14}$/,
};

export const telephoneRegex = /^\+?\d{6,}$/;

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const shipToIDRegex = /^(4)\d{7}$|^(2)\d{7}$/;

export const payerIDRegex = /^(3)\d{7}$/;

export const soldToIDRegex = /^(1)\d{7}$/;

export const billToIDRegex = /^(3)\d{7}$/;

export const pocSupplierRegex = /^(4)\d{7}$|^(2)\d{7}$/;

export const companyNumRegex = /^[A-Z0-9]{8}$/;

export const profitCenterRegex = /^[1-9]\d{3}[A-Za-z]{2}\d{2}$/;

export const glnIlnCodeRegex = /^\d{13}$/;

export const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

export const getFileNameRegex = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/;

export const vatRegex = {
  Austria: /^ATU\d{8}$/,
  Belgium: /^BE\d{10}$/,
  Denmark: /^DK\d{8}$/,
  Finland: /^FI\d{8}$/,
  France: /^FR\d{11}$/,
  Germany: /^DE\d{9}$/,
  Italy: /^IT\d{11}$/,
  Luxembourg: /^LU\d{8}$/,
  Netherlands: /^NL\d{9}B\d{2}$/,
  Norway: /^\d{9}MVA$/,
  Poland: /^PL\d{10}$/,
  Sweden: /^SE\d{12}$/,
  Switzerland: /^CHE\d{9}(MWST|TVA|IVA)$/,
  'United Kingdom': /^GB\d{9}$/,
};

export const specialCharacterRegex = /[~#$%^|}{\/;<=]/;
export const streetCharacterRegex = /[~#$%^|}{;<=]/;
export const houseNumCharacterRegex = /[~#$%^|}{;<!@*)_:>?=-]/;
export const contactPersonRegex = /[~#$%^|}{;<!@*_+:">=?]/;
export const cityRegex = /[~!@#$+%^*_={}|:"=;<>-?]/;

export const vatPlaceholder = (country) => {
  switch (country) {
    case 'Austria':
      return 'Eg: ATU12345678';

    case 'Belgium':
      return 'Eg: BE1234567890';

    case 'Denmark':
      return 'Eg: DK12345678';

    case 'Finland':
      return 'Eg: FI12345678';

    case 'France':
      return 'Eg: FR12345678901';

    case 'Germany':
      return 'Eg: DE123456789';

    case 'Italy':
      return 'Eg: IT12345678901';

    case 'Luxembourg':
      return 'Eg: LU12345678';

    case 'Netherlands':
      return 'Eg: NL123456789B01';

    case 'Norway':
      return 'Eg: 123456789MVA';

    case 'Poland':
      return 'Eg: PL12345678';

    case 'Sweden':
      return 'Eg: SE123456789012';

    case 'Switzerland':
      return 'Eg: CHE123456789(MWST/TVA/IVA)';

    case 'United Kingdom':
      return 'Eg: GB123456789';

    default:
      return '';
  }
};

export const vatLength = (country) => {
  switch (country) {
    case 'Austria':
      return 11;

    case 'Belgium':
      return 12;

    case 'Denmark':
      return 10;

    case 'Finland':
      return 10;

    case 'France':
      return 13;

    case 'Germany':
      return 11;

    case 'Italy':
      return 13;

    case 'Luxembourg':
      return 10;

    case 'Netherlands':
      return 14;

    case 'Norway':
      return 12;

    case 'Poland':
      return 12;

    case 'Sweden':
      return 14;

    case 'Switzerland':
      return 16;

    case 'United Kingdom':
      return 11;

    default:
      return 13;
  }
};

export const mobileLength = (country) => {
  switch (country) {
    case 'Netherlands':
      return 15;

    case 'United Kingdom':
      return 11;

    case 'France':
      return 11;

    case 'Belgium':
      return 15;

    case 'Germany':
      return 12;

    default:
      return 11;
  }
};

export const mobilePlaceholder = (country) => {
  switch (country) {
    case 'Netherlands':
      return 'Eg: 123456789';

    case 'United Kingdom':
      return 'Eg: 12345678901';

    case 'France':
      return 'Eg: 12345678901';

    case 'Belgium':
      return 'Eg: 1234567890';

    case 'Germany':
      return 'Eg: 123456789012';

    default:
      return 'Eg: 12345678901';
  }
};

export const telephoneLength = (country) => {
  switch (country) {
    case 'Netherlands':
      return 15;

    case 'United Kingdom':
      return 11;

    case 'France':
      return 11;

    case 'Belgium':
      return 15;

    case 'Germany':
      return 12;

    default:
      return 11;
  }
};

export const telephonePlaceholder = (country) => {
  switch (country) {
    case 'Netherlands':
      return 'Eg: 123456789';

    case 'United Kingdom':
      return 'Eg: 12345678901';

    case 'France':
      return 'Eg: 12345678901';

    case 'Belgium':
      return 'Eg: 123456789';

    case 'Germany':
      return 'Eg: 123456789012';

    default:
      return 'Eg: 12345678901';
  }
};

export const ibanLength = (country) => {
  switch (country) {
    case 'Austria':
      return 20;

    case 'Belgium':
      return 16;

    case 'Denmark':
      return 18;

    case 'Finland':
      return 18;

    case 'France':
      return 27;

    case 'Germany':
      return 22;

    case 'Italy':
      return 27;

    case 'Luxembourg':
      return 20;

    case 'Netherlands':
      return 18;

    case 'Norway':
      return 15;

    case 'Poland':
      return 28;

    case 'Sweden':
      return 24;

    case 'Switzerland':
      return 21;

    case 'United Kingdom':
      return 22;

    default:
      return 22;
  }
};

export const ibanPlaceholder = (country) => {
  switch (country) {
    case 'Austria':
      return 'Eg: AT123456789012345678';

    case 'Denmark':
      return 'Eg: DK1234567890123456';

    case 'Finland':
      return 'Eg: FI1234567890123456';

    case 'Netherlands':
      return 'Eg: NL02ABNA0123456789';

    case 'United Kingdom':
      return 'Eg: GB33BUKB20201555555555';

    case 'France':
      return 'Eg: FR7630006000011234567890189';

    case 'Belgium':
      return 'Eg: BE71096123456769';

    case 'Germany':
      return 'Eg: DE75512108001245126199';

    case 'Italy':
      return 'Eg: IT01A1234567890123456789012';

    case 'Luxembourg':
      return 'Eg: LU123456789012345678';

    case 'Norway':
      return 'Eg: NO1234567890123';

    case 'Poland':
      return 'Eg: PL12345678901234567890123456';

    case 'Sweden':
      return 'Eg: SE1234567890123456789012';

    case 'Switzerland':
      return 'Eg: CH1234567890123456789';

    default:
      return 'Eg: XX12345678901234567890';
  }
};

export const languages = [
  'Danish',
  'Dutch',
  'English',
  'Espanol',
  'Finnish',
  'French',
  'German',
  'Italian',
  'Norwegian',
  'Polish',
  'Swedish',
];

export const languageMap = {
  N: 'Dutch',
  I: 'Italian',
  E: 'English',
  D: 'German',
  F: 'French',
  V: 'Swedish',
  L: 'Polish',
  U: 'Finnish',
  O: 'Norwegian',
  K: 'Danish',
};

export const telephoneOptions = ['General/Accounting', 'Logistics', 'Invoicing', 'Ordering'];

export const einvoicingOptions = ['General/Accounting', 'Logistics', 'Invoicing', 'Ordering'];

export const subChannels = [
  { key: 'XXX', value: 'XXX - Not Applicable' },
  { key: '001', value: '001 - National Customer' },
  { key: '002', value: '002 - Convenience' },
  { key: '003', value: '003 - Independent' },
  { key: '007', value: '007 - Key Accounts' },
  { key: '008', value: '008 - Wholesalers' },
  { key: '010', value: '010 - Others' },
  { key: '016', value: '016 - Events' },
  { key: '017', value: '017 - Sports' },
];

export const exciseOptions = [
  { key: '001', val: 'Excise Exempt Custom' },
  { key: '002', val: 'Excise Duty Customer' },
];

export function numberRange(start, end) {
  return new Array(end - start).fill().map((d, i) => i + start);
}
