import { glnIlnCodeRegex, postalCodeRegex, payerIDRegex, vatRegex } from '../../../utils/Utils';

export function validateFields(values) {
  let errors = {};

  const soldToPayer2Regex = /^(3)\d{7}$/;

  const pricingHierarchyRegex = /^\d{6}$/;

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(
    sessionStorage.getItem('createCustomerMainPage'),
  ).customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  if (
    values.hierarchyTypeANode == 'Existing' &&
    values.aNode == '' &&
    country == 'United Kingdom'
  ) {
    errors.aNode = 'A-Node is required';
  } else if (
    [
      'Austria',
      'Denmark',
      'Finland',
      'Italy',
      'Norway',
      'Poland',
      'Sweden',
      'Switzerland',
    ].includes(country) &&
    values.priceList == ''
  ) {
    errors.priceList = 'Price List is required';
  } else if (
    (country == 'Netherlands' || (country == 'Belgium' && shipToType == 'On-Trade – Indirect')) &&
    values.att7 == ''
  ) {
    console.log('ok');
    errors.att7 = `${
      shipToType.endsWith('Direct') ? 'Attribute 7 / Cellar beer' : 'Use of 2sold-To'
    } is required`;
  } else if (
    country == 'Germany' &&
    !['', 'No'].includes(values.att7) &&
    values.soldToPayer2 == '' &&
    customerReqType != '2nd Sold To'
  ) {
    errors.soldToPayer2 = '2 sold-To Payer Number is required';
  } else if (
    country == 'Netherlands' &&
    shipToType.includes('Indirect') &&
    values.soldToPayer2 == ''
  ) {
    errors.soldToPayer2 = '2 sold-To Payer Number is required';
  } else if (values.soldToPayer2 != '' && !soldToPayer2Regex.test(values.soldToPayer2)) {
    errors.soldToPayer2 = '2 sold-To Payer Number is incorrect';
  } else if (
    ((country == 'Belgium' &&
      (shipToType.startsWith('National') ||
        ['Off-Trade – Direct', 'On-Trade – Indirect', 'On-Trade – Direct'].includes(shipToType))) ||
      ['Italy'].includes(country) ||
      ['France'].includes(country)) &&
    values.pricingHierarchyOpt == ''
  ) {
    errors.pricingHierarchyOpt = 'Option is required';
  } else if (
    ['Belgium', 'Italy', 'France'].includes(country) &&
    values.pricingHierarchyOpt == 'Yes' &&
    values.pricingHierarchy == ''
  ) {
    errors.pricingHierarchy = 'Pricing Hierarchy is required';
  } else if (
    !['Italy'].includes(country) &&
    values.pricingHierarchy != '' &&
    !pricingHierarchyRegex.test(values.pricingHierarchy)
  ) {
    errors.pricingHierarchy = 'Pricing Hierarchy is incorrect';
  } else if (
    ['Italy'].includes(country) &&
    values.pricingHierarchy != '' &&
    !payerIDRegex.test(values.pricingHierarchy)
  ) {
    errors.pricingHierarchy = 'Pricing Hierarchy is incorrect';
  } else if (values.ediEmailAddress != '' && !emailRegex.test(values.ediEmailAddress)) {
    errors.ediEmailAddress = 'Email is incorrect';
  } else if (
    country != 'Netherlands' &&
    values.hierarchyTypeANode == 'Existing' &&
    values.aNode != '' &&
    !pricingHierarchyRegex.test(values.aNode)
  ) {
    errors.aNode = 'A-Node is incorrect';
  } else if (
    (['Italy'].includes(country) || (country == 'Netherlands' && shipToType.includes('Direct'))) &&
    values.deliveryDay == ''
  ) {
    errors.deliveryDay = 'Delivery Day is required';
  } else if (
    ((country == 'Belgium' && shipToType == 'Off-Trade – Direct') || country == 'Italy') &&
    values.shippingCondition == ''
  ) {
    errors.shippingCondition = 'Shipping Condition is required';
  } else if (country == 'Belgium' && shipToType.includes('Wholesaler')) {
    if (values.name == '') {
      errors.name = 'Name is required';
    } else if (values.name.includes('Merger') && values.merger == '') {
      errors.merger = 'Merger is required';
    } else if (values.name.includes('Buying Group') && values.buyingGroup == '') {
      errors.buyingGroup = 'Buying Group is required';
    } else if (values.name1 == '') {
      errors.name1 = 'Name is required';
    } else if (values.postalCode == '') {
      errors.postalCode = 'Postal Code is required';
    } else if (
      values.postalCode != '' &&
      !postalCodeRegex[
        typeof values.soldCountry == 'undefined' ? 'Belgium' : values.soldCountry
      ].test(values.postalCode)
    ) {
      errors.postalCode = 'Postal Code is incorrect';
    } else if (values.houseNumber == '') {
      errors.houseNumber = 'House Number is required';
    } else if (values.street == '') {
      errors.street = 'Street is required';
    } else if (values.city == '') {
      errors.city = 'City is required';
    } else if (values.soldCountry == '') {
      errors.soldCountry = 'Country is required';
    } else if (values.region == '') {
      errors.region = 'Region is required';
    }
  } else if (country == 'France' && shipToType.includes('Off-Trade') && values.ediOption == '') {
    errors.ediOption = 'EDI is required';
  } else if (values.ediOption == 'Yes') {
    if (values.glnIlnCode == '') {
      errors.glnIlnCode = 'GLN/ILN Code is required';
    } else if (
      values.glnIlnCode != '' &&
      !glnIlnCodeRegex.test(values.glnIlnCode) &&
      country !== 'Italy'
    ) {
      errors.glnIlnCode = 'GLN/ILN Code is incorrect';
      // } else if (values.ediType == '') {
      //   errors.ediType = 'EDI Type is required';
    } else if (values.ediEmailAddress == '') {
      errors.ediEmailAddress = 'Email is required';
    } else if (values.vatRegNumber == '' && country !== 'Germany') {
      errors.vatRegNumber = 'VAT Reg Number is required';
    }
  } else if (
    values.vatRegNumber == '' &&
    country === 'France' &&
    shipToType === 'Off-Trade – Direct'
  ) {
    errors.vatRegNumber = 'VAT Reg Number is required';
  } else if (values.vatRegNumber != '' && !vatRegex[country].test(values.vatRegNumber)) {
    errors.vatRegNumber = 'VAT Reg Number is incorrect';
  } else if (values.ldeOption == 'Yes') {
    if (values.ldeType == '') {
      errors.glnIlnCode = 'LDE Type is required';
    }
  }

  return errors;
}
