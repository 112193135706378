import React, { useState, useEffect } from 'react';
import CreateCustMainPage from './mainpage/MainPage';
import CreateCustShipTo from './shipto/ShipTo';
import CreateCustPayer from './payer/Payer';
import CreateCustSoldTo from './soldto/SoldTo';
import CreateCustBillTo from './billto/BillTo';
import CreateCustReview from './review/Review';
import CreateCustSaveSuccess from './success/Success';
import CreateCustSaveError from './error/Error';

import { formTemplate } from './Data';
import { SaveDraft } from './FlowCallAPI';

const CreateCustomer = (props) => {
  const [newTicket, setNewTicket] = useState(props.createNew);

  const [activePage, setActivePage] = useState('mainpage');

  const [draftTicketNum, setDraftTicketNum] = useState('');

  const [ticketStatus, setTicketStatus] = useState('');

  const [isSave, setIsSave] = useState(false);

  const [draftMsg, setDraftMsg] = useState('');

  const [done, setDone] = useState(false);

  const mainPageForm = props.data.mainpage
    ? JSON.parse(sessionStorage.getItem('createCustomerMainPage')) || props.data.mainpage
    : JSON.parse(sessionStorage.getItem('createCustomerMainPage')) || formTemplate.mainpage;

  const shipToForm = props.data.shipto
    ? JSON.parse(sessionStorage.getItem('createCustomerShipTo')) || props.data.shipto
    : JSON.parse(sessionStorage.getItem('createCustomerShipTo')) || formTemplate.shipto;

  const payerForm = props.data.payer
    ? JSON.parse(sessionStorage.getItem('createCustomerPayer')) || props.data.payer
    : JSON.parse(sessionStorage.getItem('createCustomerPayer')) || formTemplate.payer;

  const soldToForm = props.data.soldto
    ? JSON.parse(sessionStorage.getItem('createCustomerSoldTo')) || props.data.soldto
    : JSON.parse(sessionStorage.getItem('createCustomerSoldTo')) || formTemplate.soldto;

  const billToForm = props.data.billto
    ? JSON.parse(sessionStorage.getItem('createCustomerBillTo')) || props.data.billto
    : JSON.parse(sessionStorage.getItem('createCustomerBillTo')) || formTemplate.billto;

  const saveDraft = (e) => {
    console.log('Clicked Save Draft', mainPageForm.country);

    setIsSave(true);
    console.log(draftTicketNum);
    console.log(mainPageForm);
  };

  useEffect(() => {
    console.log(`Active: ${activePage}`);
    console.log(props);
    setDraftMsg('');
  }, [activePage]);

  useEffect(() => {
    console.log(`Clearing1232131241294921481284: `, newTicket);
    console.log('tick', draftTicketNum);

    setDone(false);
    console.log('hehe', props.data.mainpage);
    if (Object.keys(props.data).length !== 0) {
      console.log('Doing');
      sessionStorage.setItem('createCustomerMainPage', JSON.stringify(props.data.mainpage));
      sessionStorage.setItem('createCustomerShipTo', JSON.stringify(props.data.shipto));
      sessionStorage.setItem('createCustomerPayer', JSON.stringify(props.data.payer));
      sessionStorage.setItem('createCustomerSoldTo', JSON.stringify(props.data.soldto));
      sessionStorage.setItem('createCustomerBillTo', JSON.stringify(props.data.billto));
      if (props.data.status === 'Rejected' || props.data.status === 'Duplicate') {
        setTicketStatus(props.data.status);
        console.log('CreateNew called for duplicate or rejected');
        var today = new Date();
        setDraftTicketNum(
          `D${today.getDate()}${
            today.getMonth() + 1
          }${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`,
        );
        console.log(`Draft: ${draftTicketNum}`);
      } else {
        console.log('set draft:', props.data.title);
        setDraftTicketNum(props.data.title);
        setTicketStatus(props.data.status);
      }
    } else if (newTicket) {
      console.log('CreateNew called');
      today = new Date();
      setDraftTicketNum(
        `D${today.getDate()}${
          today.getMonth() + 1
        }${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`,
      );
      console.log(`Draft: ${draftTicketNum}`);

      sessionStorage.setItem('createCustomerMainPage', JSON.stringify(formTemplate.mainpage));
      sessionStorage.setItem('createCustomerShipTo', JSON.stringify(formTemplate.shipto));
      sessionStorage.setItem('createCustomerPayer', JSON.stringify(formTemplate.payer));
      sessionStorage.setItem('createCustomerSoldTo', JSON.stringify(formTemplate.soldto));
      sessionStorage.setItem('createCustomerBillTo', JSON.stringify(formTemplate.billto));

      setNewTicket(false);
    } else {
      console.log('this');
    }

    setDone(true);
  }, []);

  const saveDraftRes = SaveDraft(
    props.accounts.length > 0 ? props.accounts[0].username : '',
    draftTicketNum,
    isSave,
    'Customer',
  );

  useEffect(() => {
    // if (saveDraftRes.result !== '') {
    //   setIsSave(false);
    //   let timeSave = new Date();

    //   if (saveDraftRes.result.result === 'Created') {
    //     setDraftMsg(`Draft created at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    //   } else if (saveDraftRes.result.result === 'Updated') setDraftMsg(`Draft updated at ${timeSave.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
    // }
    console.log(`Save draft res: ${saveDraftRes.result.result}`);
    setIsSave(false);
    if ('result' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.result);
    else if ('error' in saveDraftRes.result) setDraftMsg(saveDraftRes.result.error);
  }, [saveDraftRes.result]);

  return (
    draftTicketNum != '' && (
      <>
        {activePage === 'mainpage' && (draftTicketNum !== '' || props.data) && done && (
          <CreateCustMainPage
            setActivePage={setActivePage}
            formTemplate={mainPageForm}
            draftTicketNum={draftTicketNum}
            status={ticketStatus}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
          />
        )}
        {activePage === 'shipto' && done && (
          <CreateCustShipTo
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            formTemplate={shipToForm}
            status={ticketStatus}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
          />
        )}
        {activePage === 'payer' && done && (
          <CreateCustPayer
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            formTemplate={payerForm}
            status={ticketStatus}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
          />
        )}
        {activePage === 'soldto' && done && (
          <CreateCustSoldTo
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            formTemplate={soldToForm}
            status={ticketStatus}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
          />
        )}
        {activePage === 'billto' && done && (
          <CreateCustBillTo
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            formTemplate={billToForm}
            status={ticketStatus}
            saveDraft={saveDraft}
            draftMsg={draftMsg}
            translations={props.translations}
          />
        )}
        {activePage === 'review' && done && (
          <CreateCustReview
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            saveDraft={saveDraft}
            status={ticketStatus}
            draftMsg={draftMsg}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {activePage === 'success' && done && (
          <CreateCustSaveSuccess
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
        {activePage === 'error' && done && (
          <CreateCustSaveError
            setActivePage={setActivePage}
            draftTicketNum={draftTicketNum}
            accounts={props.accounts}
            translations={props.translations}
          />
        )}
      </>
    )
  );
};

export default CreateCustomer;
