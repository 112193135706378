import {
  billToIDRegex,
  postalCodeRegex,
  telephoneRegex,
  emailRegex,
  glnIlnCodeRegex,
  vatRegex,
} from '../../../utils/Utils';

export function validateFields(values) {
  let errors = {};

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const customerReqType = JSON.parse(
    sessionStorage.getItem('createCustomerMainPage'),
  ).customerReqType;

  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType;

  // Add the validation for bill to option selected. Cannot be kept empty.
  if (values.showBillTo === '') {
    errors.showBillTo = 'Please choose an option';
  } else if (values.showBillTo === 'Yes-Fill details') {
    if (values.existingBillToID !== '' && !billToIDRegex.test(values.existingBillToID)) {
      errors.existingBillToID = 'Bill-to ID is incorrect';
    } else if (values.existingBillToID === '') {
      if (values.name === '') {
        errors.name = 'Name is required';
      } else if (country == 'France' && values.taxNumber == '') {
        errors.taxNumber = 'Tax Number is required';
      } else if (country == 'France' && values.taxNumber2 == '') {
        errors.taxNumber2 = 'Tax Number 2 is required';
      } else if (values.postalCode === '') {
        errors.postalCode = 'Postal Code is required';
      } else if (
        values.postalCode !== '' &&
        !postalCodeRegex[values.billCountry].test(values.postalCode)
      ) {
        errors.postalCode = 'Postal code is incorrect';
      } else if (
        values.houseNumber === '' &&
        country !== 'United Kingdom' &&
        country !== 'France'
      ) {
        errors.houseNumber = 'House number is required';
      } else if (values.street === '') {
        errors.street = 'Street is required';
      } else if (values.city === '') {
        errors.city = 'City is required';
      } else if (values.billCountry == '') {
        errors.billCountry = 'Country is required';
      } else if (!['Luxembourg'].includes(values.billCountry) && values.region === '') {
        errors.region = 'Region is required';
      } else if (values.telephone !== '' && !telephoneRegex.test(values.telephone)) {
        errors.telephone = 'Telephone number is incorrect';
      } else if (['Netherlands'].includes(country) && values.mobile === '') {
        errors.mobile = 'Mobile number is required';
      } else if (
        country != 'Netherlands' &&
        values.mobile !== '' &&
        !telephoneRegex.test(values.mobile)
      ) {
        errors.mobile = 'Mobile number is incorrect';
      } else if (values.emailAddress === '') {
        errors.emailAddress = 'Email is required';
      } else if (values.emailAddress !== '' && !emailRegex.test(values.emailAddress)) {
        errors.emailAddress = 'Email is incorrect';
      } else if (
        country == 'France' &&
        shipToType.includes('Off-Trade') &&
        values.ediOption == ''
      ) {
        errors.ediOption = 'EDI is required';
      } else if (
        values.ediOption === 'Yes' &&
        values.glnIlnCode !== '' &&
        !glnIlnCodeRegex.test(values.glnIlnCode) &&
        country !== 'Italy'
      ) {
        errors.glnIlnCode = 'GLN/ILN CODE is incorrect';
      } else if (
        values.ediOption === 'Yes' &&
        values.ediEmail !== '' &&
        !emailRegex.test(values.ediEmail)
      ) {
        errors.ediEmail = 'Email address is incorrect';
      } else if (values.eInvEmail != '' && !emailRegex.test(values.eInvEmail)) {
        errors.eInvEmail = 'Email address is incorrect';
        // } else if (values.vatRegNumber === '' && country == 'Germany') {
        //   errors.vatRegNumber = 'VAT Reg Number is required';
      } else if (values.vatRegNumber === '' && country == 'France') {
        errors.vatRegNumber = 'VAT Reg Number is required';
      } else if (values.vatRegNumber != '' && !vatRegex[country].test(values.vatRegNumber)) {
        errors.vatRegNumber = 'VAT Reg Number is incorrect';
      }
    }
  }

  return errors;
}
