import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  Select,
  FileInput,
  Tooltip,
  ActionIcon,
  Text,
  Loader,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Card, Modal, Container, Row, Col } from 'react-bootstrap';
import TopBar from './../../components/layout/TopBar';
import FeatherIcon from 'feather-icons-react';
import SelectWithSearch from 'react-select';
// import {  IconInfoCircle, IconX, IconPlaystationX, IconFileUpload} from '@tabler/icons';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import { Constants } from '../../Constants/Constants';
import './ContactUs.css';
import './../../styles/App.css';
import configData from './Config.js';
import {
  FetchTicketData,
  FetchTranslations,
  FetchCreateAccess,
  FetchModAccess,
  FetchReviewTicketData,
  FetchAdminAccess,
} from './../FlowCallAPI';
import { loginRequest, snowBackendRequest } from '../../authConfig';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

let INC_NUM;

const ContactUs = () => {
  const [activePage, setActivePage] = useState('contactus');
  const [lang, setLang] = useState(localStorage.getItem('appLang') || 'en');
  const { instance, accounts, inProgress } = useMsal();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [files, setFiles] = useState('');
  const [issueType, setIssueType] = useState('');
  const [country, setCountry] = useState('');
  const [requestID, setRequestID] = useState('');
  const [requestIDOptions, setRequestIDOptions] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [validate, setValidate] = useState(false);
  const [SYS_ID, setSYS_ID] = useState(false);
  const fetchTranslationsRes = FetchTranslations(lang);

  const fetchCreateAccessRes = FetchCreateAccess(accounts.length > 0 ? accounts[0].username : '');
  const fetchModAccessRes = FetchModAccess(accounts.length > 0 ? accounts[0].username : '');
  const fetchAdminAccessRes = FetchAdminAccess(accounts.length > 0 ? accounts[0].username : '');

  const handleValidate = () => {
    const value = form.values;
    if (
      country.length == 0 ||
      issueType.length() == 0 ||
      value.shortDescription.length() == 0 ||
      value.issueDescription.length() == 0
    ) {
      return true;
    }
    return false;
  };
  const [errors, setErrors] = useState({
    issueType: false,
    country: false,
    shortDescription: false,
    issueDescription: false,
    requestID: false,
  });
  const changeLang = (e) => {
    console.log(`Language: ${e.target.value}`);
    localStorage.setItem('appLang', e.target.value);
    setLang(e.target.value);
  };

  const form = useForm({
    initialValues: {
      shortDescription: '',
      issueDescription: '',
    },
    validate: {
      shortDescription: (value) => value.trim().length === 0,
      issueDescription: (value) => value.trim().length === 0,
    },
  });

  const options = [
    {
      label: 'Technical',
      value: 'Technical',
    },
    {
      label: 'Business',
      value: 'Business',
    },
  ];

  const countries = [
    {
      label: 'Austria',
      value: 'Austria',
    },
    {
      label: 'Belgium',
      value: 'Belgium',
    },
    {
      label: 'Denmark',
      value: 'Denmark',
    },
    {
      label: 'Finland',
      value: 'Finland',
    },
    {
      label: 'France',
      value: 'France',
    },
    {
      label: 'Germany',
      value: 'Germany',
    },
    {
      label: 'Italy',
      value: 'Italy',
    },
    {
      label: 'Luxembourg',
      value: 'Luxembourg',
    },
    {
      label: 'Netherlands',
      value: 'Netherlands',
    },
    {
      label: 'Norway',
      value: 'Norway',
    },
    {
      label: 'Poland',
      value: 'Poland',
    },
    {
      label: 'Sweden',
      value: 'Sweden',
    },
    {
      label: 'Switzerland',
      value: 'Switzerland',
    },
    {
      label: 'United Kingdom',
      value: 'United Kingdom',
    },
  ];

  const removeAttachment = () => {
    setFiles('');
  };

  const getSysID = async () => {
    const config = {
      headers: { Authorization: `${localStorage.getItem('snow_api_access_token')}` },
    };
    const Body = {};

    // axios.get(`${configData.getSysID}${accounts[0].username}`,config).then((response) => {
    console.log('called sysid');
    axios
      .get(`${configData.getSysID}${accounts.length > 0 ? accounts[0].username : ''}`, config)
      .then((response) => {
        // console.log(response);
        setSYS_ID(response.data.result[0].sys_id);
        console.log('Inside get sys ID', SYS_ID);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setInputs = (event, action) => {
    if (action.action == 'clear') {
      setRequestID('');
    } else {
      console.log(event);
      setRequestID(event.value);
    }
  };

  const getRequestID = async () => {
    console.log('calling function');
    let body = {
      requestor: accounts.length > 0 ? accounts[0].username : '',
    };
    const response = await axios.post(configData.fetchRequestID, body);
    console.log('check this resp', response);
    setRequestIDOptions(response.data.map((value) => ({ label: value, value: value })));
  };

  useEffect(() => {
    getSysID();
    getRequestID();
  }, []);

  function getLang(id) {
    // console.log(props.translations);
    let obj = fetchTranslationsRes.result.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  const onSubmit = (e) => {
    setShowProgress(true);
    e.preventDefault();

    // console.log(e,form.values);
    const value = form.values;

    if (
      issueType === '' ||
      country === '' ||
      value.shortDescription === '' ||
      value.issueDescription === ''
    ) {
      setErrors({
        issueType: issueType === '' ? true : false,
        country: country === '' ? true : false,
        shortDescription: value.shortDescription === '' ? true : false,
        issueDescription: value.issueDescription === '' ? true : false,
      });
      setShowProgress(false);
      return;
    }
    let data = {};
    data['sys_id'] = SYS_ID;
    // data["username"] = accounts[0].username;
    data['username'] = accounts.length > 0 ? accounts[0].username : '';
    data['issueDescription'] = value.issueDescription;
    data['issueType'] = issueType;
    data['shortDescription'] = 'Country: ' + country + ' || ' + value.shortDescription;
    if (requestID != '') {
      data['shortDescription'] = 'Request ID: ' + requestID + ' || ' + data['shortDescription'];
    } else {
      data['shortDescription'] = 'Request ID: -  || ' + data['shortDescription'];
    }
    data['projectName'] = 'Maven';
    data['zone'] = 'EUR';
    data['priority'] = '3';
    data['typeOfSolution'] = 'PWA';
    data['issueType'] = 'u_performance';
    // data['pointOfContact'] = accounts[0].username;
    data['pointOfContact'] = accounts.length > 0 ? accounts[0].username : '';
    if (issueType == 'Business') {
      data['assignmentGroup'] = '3490ffe4978f2d9020ddbaffe153af2d';
    } else {
      data['assignmentGroup'] = '47259ddc97fda91071adb9b0f053af61';
    }
    // console.log(data['assignmentGroup']);
    data['typeOfSolution'] = 'PWA';
    data['businessService'] = '62a65f2fdbf92090c014fe03f3961915';

    // console.log(data);
    // console.log(files, files[0]);

    async function uploadFile(data) {
      // console.log('Inside Upload File');
      const formData = new FormData();
      formData.append('attachment', files[0]);
      formData.append('sys_id', data['IncidentSysId']);
      // console.log('Inside uploadFile',files[0],data["IncidentSysId"]);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}`,
        },
      };
      axios.post(configData.uploadFile, formData, config).then((response) => {
        console.log('File Uploaded');
      });
    }

    async function getIncidentSysID(incidentNumber) {
      // console.log('Inside Get Incident Sys ID');
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios.get(`${configData.getIncidentSysID}${incidentNumber}`, config).then((res) => {
        // console.log(res,res.data.result[0].sys_id);
        data['IncidentSysId'] = res.data.result[0].sys_id;
        // console.log(`Incident Sys ID Fetched : ${data["IncidentSysId"]}`)
        uploadFile(data);
      });
    }

    const pushSQL = async (data) => {
      // console.log('inside pushSQL');
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios.post(configData.pushSQL, data, config).then((res) => {
        // console.log(res);
        setShowProgress(false);
        handleShow();
      });
    };

    async function raiseTicket(data) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios
        .post(configData.createIncident, data, config)
        .then((res) => {
          // console.log('Inside Raise Ticket');
          // console.log(res);
          data['IncidentNumber'] = res.data.result.number;
          INC_NUM = data['IncidentNumber'];
          // console.log(INC_NUM);
          if (files[0]?.name?.length > 0) getIncidentSysID(INC_NUM);
          pushSQL(data);
        })
        .catch((e) => {
          console.log(e);
          setShowProgress(false);
          alert('Upload Error');
        });
    }
    raiseTicket(data);
  };

  function createData(Levels, Team_Role, URL_Email) {
    return { Levels, Team_Role, URL_Email };
  }

  const rows = [
    createData('Primary', 'Digital Solutions R&M', 'https://maven.ab-inbev.com/contactus'),
    createData('Level 1', 'Maven Primary POC', 'ahalya.r-ext@ab-inbev.com'),
    createData('Level 2', 'Web Apps Run & Maintain Lead', 'chaithra.b.s@ab-inbev.com'),
    createData('Level 3', 'Tech Manager', 'vamshi.thakkalapelli@ab-inbev.com'),
    createData('Level 4', 'Senior Manager', 'supriya.kalgudi@ab-inbev.com'),
  ];

  // console.log(files.length)
  return (
    <div className="contactUs">
      {/* <TopBar
        activePage={activePage}
        setActivePage={setActivePage}
        createAccess={fetchCreateAccessRes.result.length != 0}
        updateAccess={fetchModAccessRes.result.length != 0}
        adminAccess={fetchAdminAccessRes.result.length != 0}
        lang={lang}
        changeLang={changeLang}
        translations={fetchTranslationsRes.result}
      /> */}
      <Container
        fluid
        style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem' }}
        className="cardContainer"
      >
        <Row>
          <Col md={8} lg={6} sm={10} xs={11} style={{ margin: 'auto' }}>
            <form onSubmit={onSubmit}>
              <Card className="customCard">
                <div>
                  <Text
                    size="xl"
                    weight={780}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '30px', paddingLeft: '10px' }}
                  >{`Contact Us`}</Text>
                </div>
                <hr style={{ color: 'black', marginTop: '1rem', marginBottom: '1rem' }} />
                <div className="cardNote">
                  <p>
                    {getLang(
                      'Please fill the following details to create a support ticket on your queries. The team will get back to you shortly.',
                    )}
                  </p>
                </div>
                <Row className="cardInputGroup">
                  <Col md={12} className="cardInputHeader">
                    <Text size="md" weight={500} color="black" align="left">
                      {getLang('Type of Issue')} *
                    </Text>
                  </Col>
                  <Col md={5} sm={6} xs={12} className="cardInput">
                    <Select
                      placeholder="Select"
                      required
                      data={options}
                      name="issueType"
                      border="2px"
                      radius="14px"
                      value={issueType}
                      onChange={setIssueType}
                      error={errors.issueType}
                    />
                  </Col>
                </Row>
                <Row className="cardInputGroup">
                  <Col md={12} className="cardInputHeader">
                    <Text size="md" weight={500} color="black" align="left">
                      {getLang('Country')} *
                    </Text>
                  </Col>
                  <Col md={5} sm={6} xs={12} className="cardInput">
                    <Select
                      placeholder="Select"
                      required
                      data={countries}
                      name="issueType"
                      border="2px"
                      radius="14px"
                      value={country}
                      onChange={setCountry}
                      error={errors.country}
                    />
                  </Col>
                </Row>
                <Row className="cardInputGroup">
                  <Col md={12} className="cardInputHeader">
                    <Text size="md" weight={500} color="black" align="left">
                      {getLang('Request ID')}
                    </Text>
                  </Col>
                  <Col md={5} sm={6} xs={12} className="cardInput">
                    <SelectWithSearch
                      name="requestId"
                      value={requestIDOptions.filter((opt) => opt.value == requestID)}
                      isClearable
                      placeholder="Select"
                      options={requestIDOptions}
                      onChange={(e, action) => {
                        setInputs(e, action);
                      }}
                      error={errors.requestID}
                    />
                  </Col>
                </Row>
                <Row className="cardInputGroup">
                  <Col md={12} className="cardInputHeader">
                    <Text size="md" weight={500} color="black" align="left">
                      {getLang('Subject')} *
                    </Text>
                  </Col>
                  <Col md={11} sm={11} xs={12} className="cardInput">
                    <TextInput
                      placeholder="Enter Subject"
                      required
                      mt="md"
                      name="shortDescription"
                      radius="14px"
                      style={{ borderRadius: '14px', marginTop: '0px' }}
                      {...form.getInputProps('shortDescription')}
                      error={errors.shortDescription}
                    />
                  </Col>
                </Row>
                <Row className="cardInputGroup">
                  <Col md={12} className="cardInputHeader">
                    <Text size="md" weight={500} color="black" align="left">
                      {getLang('Short Description')} *
                    </Text>
                  </Col>
                  <Col md={11} sm={11} xs={12} className="cardInput">
                    <Textarea
                      mt="md"
                      required
                      placeholder="Enter Short Description"
                      maxRows={10}
                      minRows={3}
                      autosize
                      name="issueDescription"
                      border="2px"
                      radius="14px"
                      style={{ marginTop: '0px' }}
                      {...form.getInputProps('issueDescription')}
                      error={errors.issueDescription}
                    />
                  </Col>
                </Row>
                <Row className="cardInputGroup">
                  <Col md={12} className="cardInputHeader" style={{ margin: '0' }}>
                    <Text size="md" weight={500} color="black" align="left">
                      {getLang('Attachment')}
                    </Text>
                  </Col>
                  <Col md={12} className="cardNote">
                    <p style={{ margin: '0' }}>
                      (Strictly allowed File types : .zip, .png , .jpeg, .jpg, .docx, .xlsx, .csv)
                    </p>
                  </Col>
                  <Col md={6} sm={8} xs={12}>
                    <div className="upload-sec">
                      <input
                        type="file"
                        className="form-control"
                        id="mp-attachment"
                        multiple={false}
                        style={{ display: 'none' }}
                        accept="image/png,image/jpeg,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip"
                        onChange={(e) => {
                          setFiles(e.target.files);
                        }}
                      />
                      <div style={{ paddingRight: '15px' }}>
                        <label style={{ marginBottom: '0.2rem' }}>Choose Files</label>
                        <div
                          style={{
                            maxWidth: 150,
                            fontWeight: 600,
                            fontSize: 10,
                            paddingBottom: '3px',
                          }}
                        >
                          {' '}
                          (Max-Size: 2MB){' '}
                        </div>
                      </div>
                      <div>
                        <label htmlFor="mp-attachment" id="uploadEvidencebtn">
                          <Button
                            className="icon-button"
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            <FeatherIcon icon="upload" size={14} />
                          </Button>
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div
                      className="attachment-links"
                      style={{ paddingLeft: '10px', paddingTop: '30px' }}
                    >
                      {files.length > 0 ? (
                        <>
                          {files[0]?.name ? (
                            <div>
                              {files[0]?.name}
                              <FeatherIcon
                                icon="x"
                                size={14}
                                className="close-icon"
                                onClick={removeAttachment}
                                style={{ cursor: 'pointer' }}
                              ></FeatherIcon>
                            </div>
                          ) : (
                            <div>
                              {files[0]?.fileName}
                              <FeatherIcon
                                icon="x"
                                size={14}
                                className="close-icon"
                                onClick={removeAttachment}
                                style={{ cursor: 'pointer' }}
                              ></FeatherIcon>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* <div className="attachment-links" style = {{paddingLeft: "10px", paddingTop : "30px"}}>
                                  {files[0]?.name ? <div>{files[0]?.name}<FeatherIcon icon="x" size={14} className="close-icon" onClick={removeAttachment} style = {{cursor: "pointer"}}></FeatherIcon></div> : <div>{files[0]?.fileName}<FeatherIcon icon="x" size={14} className="close-icon" onClick={removeAttachment} style = {{cursor: "pointer"}}></FeatherIcon></div>}
                                </div> */}
                    {/* <div  style = {{paddingLeft: "5px", paddingTop : "30px", width: "3px", height: "3px"}}>
                                  {files[0]?.name ? <div><FeatherIcon icon="x" size={14} className="close-icon" onClick={removeAttachment} style = {{cursor: "pointer"}}></FeatherIcon></div> : ""}
                                </div> */}
                  </Col>
                </Row>
                <Group position="right" mt="xl">
                  <Link to="/">
                    <Button
                      size="md"
                      radius="xl"
                      style={{
                        height: '36px',
                        borderRadius: '14px',
                        backgroundColor: 'red',
                        boxShadow:
                          'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                      }}
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    disabled={handleValidate}
                    size="md"
                    radius="xl"
                    style={{
                      height: '36px',
                      borderRadius: '14px',
                      backgroundColor: '#f1c40f',
                      boxShadow:
                        'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                    }}
                  >
                    Submit
                  </Button>
                </Group>
              </Card>
              <div className="vertical-line" style={{ marginTop: '50px' }}>
                <h4 style={{ fontWeight: 'bold' }}>{getLang('Escalation Matrix')}</h4>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{getLang('Levels')}</TableCell>
                        <TableCell align="center">{getLang('Team/Role')}</TableCell>
                        <TableCell align="center">{getLang('URL/Email Address')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.Levels}
                          </TableCell>
                          <TableCell align="center">{row.Team_Role}</TableCell>
                          <TableCell align="center">{row.URL_Email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p className="cardNote" style={{ textAlign: 'left' }}>
                  <b>
                    {getLang(
                      'NOTE: All Issues and Change Requests should be raised via the Maven Service-Now URL Only. Email requests will NOT BE Entertained/Prioritized.',
                    )}
                  </b>
                </p>
              </div>
            </form>
          </Col>
        </Row>

        <Modal show={showProgress} backdrop="static" keyboard={false} position="center">
          <Modal.Header>
            <Modal.Title>Thank You!</Modal.Title>
          </Modal.Header>
          <Modal.Body text-align="center">
            Please wait we are communicating your query to SNOW team.
            <Loader
              variant="bars"
              color="gray"
              size="lg"
              style={{
                margin: 'auto',
                display: 'block',
                paddingTop: '15px',
              }}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          position="center"
          className="successModal"
        >
          <Modal.Header>
            <Modal.Title>Congratulations</Modal.Title>
            <Link to="/">
              <FeatherIcon icon="x" size={20} />
            </Link>
          </Modal.Header>
          <Modal.Body text-align="center">
            Your ticket has been raised with Incident Number: {INC_NUM}. On Clicking Close, you will
            be redirected to Home page.
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default ContactUs;
