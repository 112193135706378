import { useState, useEffect } from 'react';
import configData from './Config';
import { urls } from '../../../utils/Utils';
import axios from 'axios';
// import axios from './../../../utils/axios';

export function FetchProfitCenters(country, isDisableFields) {
  const url = configData.fetchProfitCentersURL;
  const [loading, setLoading] = useState(false);
  const [profitCenters, setProfitCenters] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country,
    });
    setProfitCenters(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country === 'France') {
      getData();
    }
  }, [country, isDisableFields]);

  return { loading, profitCenters, getData };
}

export function FetchRegions(country, isDisableFields) {
  const url = configData.fetchRegionsURL;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setRegions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') getData();
    else if (country == '') setRegions([]);
  }, [country]);

  return { loading, regions, getData };
}

export function FetchPaymentTerms(country, isDisableFields, shipToType) {
  const url = configData.fetchPaymentTermsURL;
  const [loading, setLoading] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country,
    });
    setPaymentTerms(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') getData();
  }, [country]);

  return { loading, paymentTerms, getData };
}

export function FetchPriceLists(country, isDisableFields) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      console.log(country);
      const res = await axios.post(urls.fetchPriceListsURL, {
        country: country,
      });
      setResult(res.data ?? []);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country != '') {
      getData();
    }
  }, [country]);

  return { loading, isError, result, getData };
}

export function FetchDeliveringPlants(country, isDisableFields) {
  const url = configData.fetchDeliveryPlantsURL;
  const [loading, setLoading] = useState(false);
  const [deliveringPlants, setDeliveringPlants] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setDeliveringPlants(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') {
      getData();
    }
  }, [country]);

  return { loading, deliveringPlants, getData };
}

export function FetchShippingConditions(country, isDisableFields) {
  const url = configData.fetchShippingConditionsURL;
  const [loading, setLoading] = useState(false);
  const [shippingConditions, setShippingConditions] = useState([]);
  var countryNew;

  const getData = async () => {
    setLoading(true);
    console.log(`Country`, countryNew);
    const res = await axios.post(url, { country: countryNew });
    setShippingConditions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') {
      countryNew = country === 'United Kingdom' ? country : 'Others';
      console.log(countryNew);
      getData();
    }
  }, [country]);

  return { loading, shippingConditions, getData };
}

export function FetchTransportationZones(country, isDisableFields) {
  const url = configData.fetchTransportationZonesURL;
  const [loading, setLoading] = useState(false);
  const [transportationZones, setTransportationZones] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setTransportationZones(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') {
      getData();
    }
  }, [country]);

  return { loading, transportationZones, getData };
}
