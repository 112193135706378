import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../../utils/Utils';
import axios from 'axios';
// import axios from '../../../utils/axios';

export function FetchTitles(country, isDisableFields) {
  const url = configData.fetchTitlesURL;
  const [loading, setLoading] = useState(false);
  const [titles, setTitles] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setTitles(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && !(country in ['', 'United Kingdom'])) getData();
  }, [country]);

  return { loading, titles, getData };
}

export function FetchPaymentTerms(country, isDisableFields, shipToType) {
  const url = configData.fetchPaymentTermsURL;
  const [loading, setLoading] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country == 'Belgium' && shipToType.includes('Wholesaler') ? 'Belgium_WHS' : country,
    });
    setPaymentTerms(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '' && shipToType != '') getData();
  }, [country, shipToType]);

  return { loading, paymentTerms, getData };
}

export function FetchPaymentMethod(country) {
  const url = configData.fetchPaymentMethodURL;
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country,
    });
    setPaymentMethod(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, paymentMethod, getData };
}

export function FetchReasonDirectDebit(country) {
  const url = configData.fetchReasonDirectDebit;
  const [loading, setLoading] = useState(false);
  const [reasonDirectDebit, setReasonDirectDebit] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      country: country,
    });
    setReasonDirectDebit(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, reasonDirectDebit, getData };
}

export function FetchPayerBapi(payerID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchPayerBapiURL;
  const [loading, setLoading] = useState(false);
  const [payerData, setPayerData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: {
        payerID: payerID,
        companyCode: companyCode,
        salesOrg: salesOrg,
      },
    });
    setPayerData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    console.log(isCopying, payerID, companyCode, salesOrg);
    if (isCopying && payerID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello');
      getData();
    }
  }, [payerID, companyCode, salesOrg, isCopying]);

  return { loading, payerData, getData };
}
