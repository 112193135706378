import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/Login.css';
import ABILogo from '../../assets/images/abi_logo_black.png';
import ACOELogo from '../../assets/images/acoe_logo.png';
import DSCOELogo from '../../assets/images/dscoe_logo.png';
import BtLogo from '../../assets/images/bt_logo.png';
import MavenLogo from '../../assets/images/maven_icon.png';
import { Card } from 'react-bootstrap';
import { Group, Button, Title } from '@mantine/core';

import { loginRequest } from '../../authConfig';
import { FetchUserRole } from '../../pages/FlowCallAPI';
import { UserContext } from '../../context/UserContext';
// import { UserContext, UserContextProvider } from '../../context/UserContext';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';

const Login = () => {
  const navigate = useNavigate();

  const { instance, accounts, inProgress } = useMsal();
  // const [userState, userDispatch] = useContext(UserContext);

  const isAuthenticated = useIsAuthenticated();
  const { user, userDispatch } = useContext(UserContext);

  function authFlow() {
    console.log('Started auth flow func');
    console.log(accounts[0]);

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        localStorage.setItem('id_token', response?.idToken);
        localStorage.setItem('access_token', response?.accessToken);

        FetchUserRole(accounts[0].username).then((response) => {
          userDispatch({ type: 'SET_USER', payload: response });
        });
      })
      .catch((err) => {
        console.log(`Error occured while acquiring token: ${err}`);
        instance.logout({
          account: accounts.length > 0 ? accounts[0] : null,
        });
      });
  }

  useEffect(() => {
    console.log(`AUTH LOG = ${isAuthenticated}`);
    if (isAuthenticated) {
      // authFlow();
      navigate('/home');
    }
  }, [isAuthenticated]);
  // localStorage.clear();
  const [rotate, setRotate] = useState(true);
  rotate ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  return (
    !isAuthenticated && (
      <div className="login-page">
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">
              <Card>
                <Card.Body className="p-4">
                  <div className="text-center mb-6">
                    <img
                      src={MavenLogo}
                      className="h-9"
                      alt="Maven Logo"
                      style={{ borderRadius: '1.5rem' }}
                    />
                  </div>

                  <div className="card-title text-center">
                    <Title
                      order={1}
                      weight={400}
                      color="yellow"
                      mb={30}
                      style={{ color: '#e3af32', weight: '400' }}
                    >{`Maven`}</Title>
                    <p>
                      {`Please use your `}
                      <strong
                        className="golden-text"
                        style={{ color: '#e3af32' }}
                      >{`AB InBev ID`}</strong>
                      {` to login`}
                    </p>
                  </div>

                  <div className="form-footer">
                    <Group position="center">
                      <Button
                        className="btn btn-primary btn-block"
                        style={{ borderRadius: '40px' }}
                        onClick={() => instance.loginRedirect(loginRequest)}
                      >
                        {`Login`}
                      </Button>
                    </Group>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className="text-center">
                    <img
                      src={ABILogo}
                      className="h-5"
                      alt="AB InBev Logo"
                      style={{ paddingRight: '10px' }}
                    />
                    <img
                      src={DSCOELogo}
                      className="h-9"
                      alt="DSCOELogo"
                      style={{
                        borderLeft: '1px solid #c9c9c9',
                        paddingLeft: '1rem',
                      }}
                    />
                  </div>
                </Card.Footer>
              </Card>
              {/* <div className="text-center mb-6">
                <img
                  src={ABILogo}
                  className="h-6"
                  alt="AB InBev Logo"
                  style={{ paddingRight: '10px' }}
                />
                <img
                  src={ACOELogo}
                  className="h-9"
                  alt="ACOE Logo"
                  style={{ borderLeft: '1px solid #c9c9c9', paddingLeft: '14px' }}
                />
              </div>

              <form className="card">
                <div className="card-body p-6">
                  <div className="card-title text-center">
                    <h4>Customer Master Data Portal</h4>
                  </div>

                  <div className="form-footer">
                    <Button
                      className="btn btn-abi-custom btn-block"
                      onClick={() => instance.loginRedirect(loginRequest)}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Login;
