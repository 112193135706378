import React, { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { FetchAllRequests, FetchCustomerRequestList, FetchCountryList } from './FlowCallAPI';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Chip, { ChipProps } from '@mui/material/Chip';
import { red, blue, yellow, green, orange } from '@mui/material/colors';
//nested data is ok, see accessorKeys in ColumnDef below
import Table2 from '../../components/layout/Tables/MaterialReactTable/Table2';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePickerInput } from '@mantine/dates';

const AllTickets = (props) => {
  const data = FetchAllRequests();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const requestTypeList = ['Block/Unblock', 'Creation', 'Hierarchy', 'Modification', 'Plant'];
  const [customerRequest, setCustomerRequest] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const customerRequestListRes = FetchCustomerRequestList();
  const countryListRes = FetchCountryList();
  const statusList = ['Awaiting Approval', 'Completed', 'Duplicate', 'In Progress', 'Rejected'];
  function handleTicketClick(ticketNum, reqType, creationType, customerRequest) {
    console.log('TICKET CLICK', ticketNum, reqType);
    navigate({
      pathname: `/request/${ticketNum}`,
      state: {
        creationType: creationType,
      },
    });
    props.RequestCreationType(ticketNum, creationType, customerRequest, reqType);
    //props.openRequest(reqType,creationType);
    console.log('CUSTOMER DATA CLICK', ticketNum, reqType, creationType);
    // props.openRequest(ticketNum, reqType,creationType);
    // // props.openRequest(ticketNum, reqType);
    // console.log(e.target);
    // if (e.target.getAttribute('data-ticket')) props.openRequest(e.target.getAttribute('data-ticket'), e.target.getAttribute('data-reqtype'));
  }
  useEffect(() => {
    setCustomerRequest(
      customerRequestListRes.resData.map((val) => {
        return val.CustomerReqTypes;
      }),
    );
    setCountryList(
      countryListRes.resData.map((val) => {
        return val.country;
      }),
    );
  }, [customerRequestListRes.resData]);
  //should be memoized or stable
  function getChipProps(row) {
    if (row == 'Rejected' || row.startsWith('Duplicate')) {
      return {
        // icon: <WarningIcon style={{ fill: red[500] }} />,
        label: row,
        style: {
          color: '#fff',
          borderColor: red[500],
          backgroundColor: red[500],
        },
      };
    } else if (row.startsWith('Awaiting')) {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
        label: row,
        style: {
          color: '#000',
          borderColor: yellow[500],
          backgroundColor: yellow[500],
        },
      };
    } else if (row.startsWith('Completed')) {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
        label: row,
        style: {
          color: '#fff',
          borderColor: green[500],
          backgroundColor: green[500],
        },
      };
    } else {
      return {
        // icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
        label: row,
        style: {
          color: '#fff',
          borderColor: orange[500],
          backgroundColor: orange[500],
        },
      };
    }
  }
  const Is_Expanding_Detail_Panel = {
    Is_Expanding: true,
  };
  const TABLE_COLUMNS = [
    {
      accessorKey: 'Request_ID',
      id: 'Request_ID',
      header: 'Request ID',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 200,
      Cell: (row) => {
        return (
          <Link
            to={{
              pathname:
                (props.requestType === 'pending' ? `/review` : `/request`) +
                `/${row.row.original.Request_ID}`,
              state: { creationType: row.row.original.Request_ID },
            }}
            onClick={() => {
              console.log('on click data grid', row);
              handleTicketClick(
                row.row.original.Request_ID,
                row.row.original.Request_Type,
                row.row.original.CustomerRequest,
                // SubmittedRequestType,
                row.row.original.CustomerRequest,
              );
            }}
          >
            {row.row.original.Request_ID}
          </Link>
        );
      },
    },
    {
      // accessorFn: (row) => new Date(row.Created), //convert to Date for sorting and filtering
      accessorKey: 'Created',
      id: 'Created',
      header: 'Created',
      // filterFn: (row, id, filterValue) => {
      //   const startDate = filterValue[0]; // Start date from filter value
      //   const endDate = filterValue[1]; // End date from filter value
      //   console.log('Created date filter values', startDate, endDate);
      //   if (!startDate && !endDate) {
      //     return true; // No filter applied
      //   }

      //   const createdDate = new Date(row.created); // Convert row's created date string to Date object

      //   if (startDate && endDate) {
      //     // Both start and end date provided
      //     return createdDate >= startDate && createdDate <= endDate;
      //   } else if (startDate) {
      //     // Only start date provided
      //     return createdDate >= startDate;
      //   } else {
      //     // Only end date provided
      //     return createdDate <= endDate;
      //   }
      // },
      // filterFn: 'inclusiveBetween',
      // sortingFn: 'datetime',
      // Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
      // // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      // Filter: ({ column }) => (
      //   // <LocalizationProvider dateAdapter={AdapterDayjs}>
      //   //   <DatePicker
      //   //     onChange={(newValue) => {
      //   //       column.setFilterValue(newValue);
      //   //     }}
      //   //     slotProps={{
      //   //       textField: {
      //   //         helperText: 'Filter Mode: Less Than',
      //   //         sx: { minWidth: '120px' },
      //   //         variant: 'standard',
      //   //       },
      //   //     }}
      //   //     value={column.getFilterValue()}
      //   //   />
      //   // </LocalizationProvider>
      //   // <LocalizationProvider dateAdapter={AdapterDayjs}>
      //   <DatePickerInput
      //     clearable
      //     type="range"
      //     onChange={(newValue) => {
      //       if (newValue !== null || newValue !== '') {
      //         column.setFilterValue(newValue);
      //       }
      //     }}
      //     // renderInput={(startProps, endProps) => (
      //     //   <React.Fragment>
      //     //     <TextField {...startProps} variant="outlined" label="Start Date" />
      //     //     <TextField {...endProps} variant="outlined" label="End Date" />
      //     //   </React.Fragment>
      //     // )}
      //     // slotProps={{
      //     //   textField: {
      //     //     helperText: 'Filter Mode: Less Than',
      //     //     sx: { minWidth: '120px' },
      //     //     variant: 'standard',
      //     //   },
      //     // }}
      //     value={column.getFilterValue()}
      //  />
      // </LocalizationProvider>
      // ),
      flex: 1,
      type: 'date',
      columnDefType: 'data',
      //filterVariant: 'date-range',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
    {
      accessorKey: 'Request_Type',
      id: 'Request_Type',
      header: 'Request Type',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      filterVariant: 'multi-select',
      filterSelectOptions: requestTypeList,
      minWidth: 200,
    },
    {
      accessorKey: 'CustomerRequest',
      id: 'CustomerRequest',
      header: 'Customer Request',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      filterVariant: 'multi-select',
      filterSelectOptions: customerRequest,
      minWidth: 350,
    },
    {
      accessorKey: 'MainCountry',
      id: 'MainCountry',
      header: 'Country',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      filterVariant: 'multi-select',
      filterSelectOptions: countryList,
      minWidth: 140,
    },
    {
      accessorKey: 'Name',
      id: 'Name',
      header: 'Name',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 140,
    },
    {
      accessorKey: 'Bot_SoldToID',
      id: 'Bot_SoldToID',
      header: 'Sold To ID',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 90,
    },
    {
      accessorKey: 'Bot_PayerID',
      id: 'Bot_PayerID',
      header: 'Payer ID',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 110,
    },
    {
      accessorKey: 'Bot_ShipToID',
      id: 'Bot_ShipToID',
      header: 'Ship To ID',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 110,
    },
    {
      accessorKey: 'BOT_BillToID',
      id: 'BOT_BillToID',
      header: 'Bill To ID',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      minWidth: 110,
    },
    {
      accessorKey: 'FinalStatus',
      id: 'FinalStatus',
      header: 'Status',
      flex: 1,
      columnDefType: 'data',
      cellClassName: 'dashboardCell',
      filterVariant: 'multi-select',
      filterSelectOptions: statusList,
      minWidth: 120,
      Cell: (row) => {
        return <Chip variant="outlined" {...getChipProps(row.row.original.FinalStatus)} />;
      },
    },
  ];
  // setTableColumns(TABLE_COLUMNS);
  useEffect(() => {
    setTableData(data.resData);
  }, [data.resData]);
  return (
    <Table2
      tableData={tableData}
      tableColumns={TABLE_COLUMNS}
      loading={data.loading}
      Is_Expanding_Detail_Panel={Is_Expanding_Detail_Panel}
    />
  );
};

export default AllTickets;
