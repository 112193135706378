import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  useEffect(() => {
    sessionStorage.setItem('updateCustomerNameDelivPlantUpdate', JSON.stringify(fields));
  }, [fields]);

  return [
    fields,
    function (event, action) {
      if (action?.name == 'pocSupplier') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            pocSupplier: '',
            pocSupplierDesc: '',
          });
        } else {
          console.log(event)
          setValues({
            ...fields,
            pocSupplier: event.pocNumber,
            pocSupplierDesc: event.pocDesc,
          });
        }
      } else {
        if (event.target.name == 'existingSoldToID') {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
            existingShipToID: '',
            name: '',
            deliveringPlant: '',
            deliveringPlantDesc: '',
            telephone: '',
            mobile: '',
            email: '',
            shippingCondition: '',
            shippingConditionDesc: '',
            pocSupplier: '',
          });
        } else {
          setValues({
            ...fields,
            [event.target.name]: event.target.value.toUpperCase(),
          });
        }
      }
    },
  ];
}
